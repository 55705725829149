
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Checkbox,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const AccountPassbook = () => {
  const [branch, setBranch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [frontPageData, setFrontPageData] = useState(null);
  const [showFrontPage, setShowFrontPage] = useState(false);
  const [showTransactionPage, setShowTransactionPage] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [frontPageDetails, setFrontPageDetails] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch all accounts
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching account data:", error);
      });
  }, [token]);

  const handleSearchClick = () => {
    if (branch) {
      const account = accounts.find(
        (acc) => acc.savingAccountNumber === branch
      );
      setSelectedAccount(account);
      fetchTransactionData(account.savingAccountNumber);
      setShowTable(true);
      setShowFrontPage(false);
      setShowTransactionPage(false);
    }
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
    setShowTable(false);
    setShowFrontPage(false);
    setShowTransactionPage(false);
  };

  //   const handleFrontPageClick = () => {
  //     if (branch) {
  //       const account = accounts.find(
  //         (acc) => acc.savingAccountNumber === branch
  //       );
  //       setSelectedAccount(account);
  //       fetchFrontPageDetails(account.savingAccountNumber);
  //       setShowFrontPage(true);
  //       setShowTable(false);
  //       setShowTransactionPage(false);
  //     }
  //   };

  const handleFrontPageClick = () => {
    if (branch) {
      fetchFrontPageData(branch);
      setShowFrontPage(true);
      setShowTransactionPage(false);
      setShowTable(false);
    }
  };

  const handleTransactionClick = () => {
    if (branch) {
      const account = accounts.find(
        (acc) => acc.savingAccountNumber === branch
      );
      setSelectedAccount(account);
      fetchTransactionData(account.savingAccountNumber);
      setShowTransactionPage(true);
      setShowTable(false);
      setShowFrontPage(false);
    }
  };

  const fetchTransactionData = (accountNumber) => {
    axios
      .get(
        `https://api.vlnidhi.com/api/admin/transactions/getpassbook/${accountNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
      });
  };

  //   const fetchFrontPageDetails = (accountNumber) => {
  //     axios
  //       .get(
  //         `https://api.vlnidhi.com/api/admin/saving-accounts/getpassbookfront/${accountNumber}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         setFrontPageDetails(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching front page details:", error);
  //       });
  //   };

  const fetchFrontPageData = (accountNo) => {
    axios
      .get(
        `https://api.vlnidhi.com/api/admin/saving-accounts/getpassbookfront/${accountNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFrontPageData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching front page data:", error);
      });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        sx={{
          marginTop: { xs: "5%", md: "2%" },
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          textAlign: "start",
        }}
      >
        Account Passbook
      </Typography>

      {/* Form Paper */}
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <Typography variant="h6" sx={{ textAlign: "left", mb: 1 }}>
          Search Details for Print
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: { xs: 1, sm: 0.5 },
          }}
        >
          {/* Select Branch Name */}
          <FormControl
            sx={{
              minWidth: { xs: "100%", sm: "50%", md: "25%" },
              flex: 1,
            }}
          >
            <InputLabel>Select by Account No.*</InputLabel>
            <Select
              label="Select by Account No.*"
              value={branch}
              onChange={handleBranchChange}
              sx={{ width: "100%" }}
            >
              {accounts.map((account) => (
                <MenuItem
                  key={account.savingAccountNumber}
                  value={account.savingAccountNumber}
                >
                  {`${account.member.memberName} (${account.savingAccountNumber})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              marginLeft: { xs: 0, sm: "4%" },
              flexDirection: { xs: "column", sm: "row" },
              gap: 0.5,
              flex: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#d4af37", color: "#000" }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#d2691e", color: "#fff" }}
              onClick={handleFrontPageClick}
            >
              Front Page
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#1e90ff", color: "#fff" }}
              onClick={handleTransactionClick}
            >
              Transaction
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#8b0000", color: "#fff" }}
            >
              Heading
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Front Page Paper */}
      {showFrontPage && frontPageData && (
        <Paper elevation={3} sx={{ padding: 10, marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "18px", md: "24px" },
                  marginBottom: 1,
                }}
              >
                VENKETLAXMI URBAN PUNE NIDHI LTD
              </Typography>
              <Typography sx={{ marginBottom: 1 }}>
                Reg. Office : SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                ROAD, BANER - MAHARASHTRA - 411045
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                Member No.: {frontPageData.member.memberCode || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: 1 }}>
                Account No.: {frontPageData.savingAccountNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: 1 }}>
                Account Holder Name: {frontPageData.member.memberName || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Date Of Birth: {frontPageData.member.dob || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Mobile No.: {frontPageData.member.mobileNo || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Email ID: {frontPageData.member.emailId || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Aadhaar No.: {frontPageData.member.aadharNo || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Branch: {frontPageData.branchName || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                IFSC Code: {frontPageData.ifscCode || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Account Type: {frontPageData.modeOfOperation}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{ marginBottom: 1 }}>
                Date Of Issue:{" "}
                {new Date(frontPageData.openingDate).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: 1 }}>
                Nomination Name: {frontPageData.nomineeName}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  fontSize: "16px",
                }}
              >
                Authorized Signatory
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Table Paper */}
      {showTable && transactions.length > 0 && (
        <Paper elevation={3} sx={{ marginTop: 2, padding: 2 }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Transaction Data List
          </Typography>
          <Table
            sx={{ width: "100%", overflowX: "auto", borderRadius: "12px" }}
            component={Paper}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#413AF1",
                  padding: "13px 46px 13px 46px",
                }}
              >
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  INO
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  TXN Date
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  A/C No
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Credit
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Debit
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Balance
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  TXN ID
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Checkbox
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, index) => (
                <TableRow key={transaction.transactionId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{transaction.transactionDate}</TableCell>
                  <TableCell>{transaction.accountNo}</TableCell>
                  <TableCell>{transaction.credit}</TableCell>
                  <TableCell>{transaction.debit}</TableCell>
                  <TableCell>{transaction.balance}</TableCell>
                  <TableCell>{transaction.transactionId}</TableCell>
                  <TableCell>{transaction.status}</TableCell>
                  <TableCell>
                    <Checkbox disabled />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          >
            <Button variant="contained" startIcon={<PrintIcon />}>
              Re-Print
            </Button>
          </Box>
        </Paper>
      )}

      {/* Transaction Page Paper */}
      {showTransactionPage && transactions.length > 0 && (
        <Paper elevation={3} sx={{ marginTop: 2, padding: 2 }}>
          <Box
            sx={{
              width: "100%",
              height: "48px",
              borderRadius: "20px 20px 0px 0px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "16px",
            }}
          >
            <Button>
              <PrintIcon />
            </Button>
          </Box>
          <Table
            sx={{
              width: "100%",
              overflowX: "auto",
              mt: 1,
              borderRadius: "12px",
            }}
            component={Paper}
          >
            <TableBody sx={{ padding: "2" }}>
              <TableRow
                sx={{
                  backgroundColor: "#413AF1",
                  padding: "13px 46px 13px 46px",
                }}
              >
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  CREDIT
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  DEBIT
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  BALANCE
                </TableCell>
              </TableRow>
              {transactions.map((transaction) => (
                <TableRow
                  sx={{
                    // backgroundColor: "#413AF1",
                    padding: "13px 46px 13px 46px",
                  }}
                >
                  <TableCell sx={{ textAlign: "center" }}>
                    {transaction.transactionDate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {transaction.remark || "N/A"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {transaction.credit}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {transaction.debit}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {transaction.balance}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          ></Box>
        </Paper>
      )}
    </Box>
  );
};

export default AccountPassbook;
