
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  TableContainer,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PrintIcon from "@mui/icons-material/Print";
import axios from "axios";

const SavingStatement = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [statementData, setStatementData] = useState([]);
  const [showStatement, setShowStatement] = useState(false);

  const token = localStorage.getItem("token"); // Ensure the token is stored in localStorage

  // Fetch account numbers when the component mounts
  useEffect(() => {
    // Fetch all account numbers
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data); // Assuming the data is an array of account objects
      })
      .catch((error) => {
        console.error("Error fetching accounts:", error);
      });
  }, [token]);

  const handleSearch = () => {
    if (!selectedAccount || !fromDate || !toDate) return;

    // Fetch transaction data based on the selected account and date range
    axios
      .get("https://api.vlnidhi.com/api/admin/transactions/gettransaction", {
        params: {
          savingAccountNumber: selectedAccount,
          startDate: fromDate,
          endDate: toDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        setStatementData(response.data);
        setShowStatement(true);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "20px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "1200px",
        margin: "auto",
        mt: 4,
      }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        Search Box
      </Typography>
      <Grid container spacing={2} alignItems="center">
        {/* Select A/C No. */}
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Select A/C No."
            value={selectedAccount}
            onChange={(e) => setSelectedAccount(e.target.value)}
            sx={{ height: "48px" }}
          >
            {accounts.map((account) => (
              <MenuItem
                key={account.savingAccountNumber}
                value={account.savingAccountNumber}
              >
                {account.member?.memberName || "Unnamed"} (
                {account.savingAccountNumber || "No Account No"})
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* From Date */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="From Date"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            sx={{ height: "48px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <CalendarTodayIcon /> */}
                </InputAdornment>
              ),
              inputProps: {
                pattern: "\\d{4}-\\d{2}-\\d{2}",
              },
            }}
          />
        </Grid>

        {/* To Date */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="To Date"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            sx={{ height: "48px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <CalendarTodayIcon /> */}
                </InputAdornment>
              ),
              inputProps: {
                pattern: "\\d{4}-\\d{2}-\\d{2}",
              },
            }}
          />
        </Grid>

        {/* Search Button */}
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: "48px",
              backgroundColor: "#D0B24F",
              color: "#fff",
              "&:hover": { backgroundColor: "#B89B3E" },
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {showStatement && statementData.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              maxWidth: "1116px",
              height: "48px",
              backgroundColor: "#D9D9D9",
              borderRadius: "20px 20px 0 0",
              padding: "0 16px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              margin: "0 auto 20px",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              Account Statement
            </Typography>
            <IconButton>
              <PrintIcon />
            </IconButton>
          </Box>


          <Grid container spacing={2} sx={{ width: "1116px", margin: "auto" }}>
            <Grid item xs={6}>
              <Typography>
                Account Number: {statementData[0]?.acccountNo}
              </Typography>
              <Typography>
                A/C Holder Name: {statementData[0]?.accountHolderName}
              </Typography>
              <Typography>
                Relative Name: {statementData[0]?.relativeName}
              </Typography>
              <Typography>Relative Relation: ------</Typography>
              <Typography>Address: {statementData[0]?.address}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Op. Date: {statementData[0]?.openingDate}</Typography>
              <Typography>
                Member Code: {statementData[0]?.memberCode}
              </Typography>
              <Typography>PAN: {statementData[0]?.panNo}</Typography>
              <Typography>
                Mode Of Op.: {statementData[0]?.modeOfOperation}
              </Typography>
              <Typography>
                Op. Branch: {statementData[0]?.branchName}
              </Typography>
            </Grid>
          </Grid>

          <TableContainer
            component={Paper}
            sx={{ mt: 3, borderRadius: "12px" }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "#413AF1" }}>
                <TableRow>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Account No.
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    TXN Date
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Inst No
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Deposit
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Withdrawal
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Balance
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Pay Mode
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    sx={{ padding: "13px 16px 13px 8px", color: "#fff" }}
                  >
                    Remarks
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statementData.map((transaction, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
                    }}
                  >
                    <TableCell>{transaction.acccountNo}</TableCell>
                    <TableCell>{transaction.transaDate}</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>{transaction.deposite}</TableCell>
                    <TableCell>{transaction.withdrwal}</TableCell>
                    <TableCell>{transaction.balance}</TableCell>
                    <TableCell>{transaction.paymentBy}</TableCell>
                    <TableCell>{transaction.branchName}</TableCell>
                    <TableCell>{transaction.remarks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default SavingStatement;
