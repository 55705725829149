import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Box,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

const SavingDepositWithdraw = () => {
  const [closingBranches, setClosingBranches] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [formData, setFormData] = useState({
    paymentBy: "",
    txnDate: "",
    closingBranch: "",
    accountNumber: "",
    memberCode: "",
    memberName: "",
    mobileNo: "",
    sbPlanName: "",
    availableBalance: "",
    transactionFor: "",
    remarks: "",
    txnType: "",
    amount: "",
    transactionType: " ",
    transactionDate: '',
  });
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch saving accounts
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const formattedAccountOptions = response.data.map((account) => ({
          value: account.savingAccountNumber,
          label: `${account.member?.memberName || "Unknown Member"} - ${account.savingAccountNumber}`,
          accountData: account,
        }));
        setAccountOptions(formattedAccountOptions);
      })
      .catch((error) => console.error("Error fetching account numbers:", error));

    // Fetch closing branches
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setClosingBranches(response.data))
      .catch((error) => console.error("Error fetching closing branches:", error));
  }, []);

  const handleAccountChange = async (event) => {
    const accountNumber = event.target.value;
    const account = accountOptions.find((acc) => acc.value === accountNumber);
    if (account) {
      const { accountData } = account;
      setSelectedAccount(accountData);
      setFormData({
        ...formData,
        accountNumber: accountData.savingAccountNumber,
        memberCode: accountData.member?.memberCode || "",
        memberName: accountData.member?.memberName || "",
        mobileNo: accountData.member?.mobileNo || "",
        sbPlanName: accountData.selectPlan || "",
        openingAmount: accountData.openingAmount || "",
      });

      // Fetch transactions for the selected account
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://api.vlnidhi.com/api/admin/transactions/getpassbook/${accountNumber}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTransactions(response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "https://api.vlnidhi.com/api/admin/transactions/make-transaction",
        {
          transactionDate: formData.transactionDate,
          memberCode: formData.memberCode,
          branchCode: formData.closingBranch,
          savingAcoountNo: formData.accountNumber,
          mobileNumber: formData.mobileNo,
          jointHolder: formData.memberName,
          sbPlanName: formData.sbPlanName,
          transactionFor: formData.transactionFor,
          transactionType: formData.txnType,
          amount: formData.amount,
          remarks: formData.remarks,
          paymentBy: formData.paymentBy,
          transactionType: formData.transactionType,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Transaction successful");
    } catch (error) {
      console.error("Error making transaction:", error);
    }
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));


  return (
    <Container maxWidth="lg">
      <Typography
        sx={{
          marginTop: { xs: "5%", md: "2%" },
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          textAlign: "start",
        }}
      >
        Savings Deposit/Withdrawal
      </Typography>

      <Paper sx={{ padding: "2%", marginTop: "2%" }}>
        <Typography
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: 500,
            marginBottom: "2%",
            lineHeight: "30px",
          }}
        >
          Account Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Txn Date*
            </Typography>
            <TextField
              fullWidth
              name="transactionDate" // Ensure name matches formData key
              value={formData.transactionDate} // Bind value to formData
              onChange={handleFormChange}
              type="date"
              // value={formData.openingDate}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Select Branch Name*
            </Typography>
            <FormControl fullWidth>
              <Select
                name="closingBranch"
                value={formData.closingBranch}
                onChange={handleFormChange}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  "& .MuiInputBase-root": {
                    height: { xs: "40px", md: "48px" },
                  },
                }}
              >
                {closingBranches.map((branch) => (
                  <MenuItem key={branch.branchId} value={branch.branchName}>
                    {branch.branchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
      <Typography
        sx={{
          width: "100%",
          fontSize: { xs: "16px", md: "18px" },
          marginBottom: "8px",
        }}
      >
        Select Account Number*
      </Typography>
      <FormControl fullWidth>
              <InputLabel id="account-number-label">Select</InputLabel>
              <Select
                labelId="account-number-label"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleAccountChange}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  "& .MuiInputBase-root": {
                    height: { xs: "40px", md: "48px" },
                  },
                }}
              >
                {accountOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
        <Typography
          sx={{
            width: "100%",
            fontSize: { xs: "16px", md: "18px" },
            marginBottom: "8px",
          }}
        >
          Member Code*
        </Typography>
        <TextField
              fullWidth
              value={formData.memberCode}
              name="memberCode"
              InputProps={{
                readOnly: true,
              }}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            "& .MuiInputBase-root": {
              height: { xs: "40px", md: "48px" },
            },
          }}
        />
      </Grid>

        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Member Name*
            </Typography>
            <TextField
              fullWidth
              name="memberName"
              value={formData.memberName}
              onChange={handleFormChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Mobile Number**
            </Typography>
            <TextField
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleFormChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              SB Plan Name*
            </Typography>
            <TextField
              name="sbPlanName"
              value={formData.sbPlanName}
              onChange={handleFormChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Save Button */}
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="center"
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Applicant Photo
            </Typography>
            <ImageUploadWrapper>
              <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="c"
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Signature
            </Typography>
            <ImageUploadWrapper>
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ padding: "2%", marginTop: "2%" }}>
        <Typography
          sx={{
            marginTop: { xs: "5%", md: "2%" },
            fontSize: { xs: "20px", md: "24px" },
            fontWeight: 600,
            lineHeight: "36px",
            textAlign: "start",
          }}
        >
          Payment Details
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Ava. Balance *
            </Typography>
            <TextField
              fullWidth
              name="openingAmount"
              value={formData.openingAmount}
              onChange={handleFormChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Transaction For*
            </Typography>
            <TextField
              fullWidth
              name="transactionFor" // Ensure name matches formData key
              value={formData.transactionFor              } // Bind value to formData
              onChange={handleFormChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Remarks*
            </Typography>
            <TextField
              fullWidth
              name="remarks" // Ensure name matches formData key
              value={formData.remarks} // Bind value to formData
              onChange={handleFormChange} // Handle change to update formData
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
  <Typography
    sx={{
      width: "100%",
      fontSize: { xs: "16px", md: "18px" },
      marginBottom: "8px",
    }}
  >
    Txn. Type*
  </Typography>
  <FormControl fullWidth>
    <InputLabel id="txn-type-label">Select</InputLabel>
    <Select
      labelId="txn-type-label"
          name="transactionType"
      value={formData.transactionType }
      onChange={handleFormChange}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
        },
      }}
    >
      <MenuItem value="Credit">Deposit</MenuItem>
      <MenuItem value="Debit">Withdrawal</MenuItem>
    </Select>
  </FormControl>
       </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Amount*
            </Typography>
            <TextField
              fullWidth
              name="amount" // Ensure name matches formData key
              value={formData.amount} // Bind value to formData
              onChange={handleFormChange} // Handle change to update formData
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
        <Typography
          sx={{
            width: "100%",
            fontSize: { xs: "16px", md: "18px" },
            marginBottom: "8px",
          }}
        >
          Payment By*
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="paymentBy-label">Select</InputLabel>
          <Select
            labelId="paymentBy-label"
            id="paymentBy"
            name="paymentBy"
            value={formData.paymentBy} // Controlled component value
            onChange={handleFormChange} // Handle changes
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              "& .MuiInputBase-root": {
                height: { xs: "40px", md: "48px" },
              },
            }}
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Check">Check</MenuItem>
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="NEFT">NEFT</MenuItem>
          </Select>
        </FormControl>
      </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ marginTop: '2%' }}>
        <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
            width: { xs: '100%', md: '247px' },
            height: '48px',
            backgroundColor: '#1976d2',
            borderRadius: '10px',
            padding: '12px 103px',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '2px',
            }}
        >
            Save
        </Button>
        </Grid>

      </Paper>
      {/* Second Paper (Table Section) */}
      <Paper
        sx={{ padding: "2%", marginTop: "4%", borderRadius: "10px 10px 0 0" }}
      >
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "20px" },
              fontWeight: 500,
              marginBottom: "2%",
            }}
          >
            Scheme List
          </Typography>
          <Button>
            <DownloadIcon />
          </Button>
        </Grid>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#413AF1" }}>
              <TableCell sx={{ color: "white", textAlign: "center" }}>INO</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>BRANCH</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>TXN DATE</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>A/C NO</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>CREDIT</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>DEBIT</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>BALANCE</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>PAY MODE</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>REMARKS</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>TXN ID</TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>USER ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row, index) => (
              <TableRow
                key={index}
                sx={{ backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB" }}
              >
                <TableCell sx={{ textAlign: "center" }}>{index + 1}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.branchName}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.transactionDate}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.accountNo}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.credit}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.debit}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.balance}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.paymentBy}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.remark}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.transactionId}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>{row.userId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
    </Container>
  );
};

export default SavingDepositWithdraw;








// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Paper,
//   Grid,
//   Box,
//   Typography,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Select,
//   TextField,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
// } from "@mui/material";
// import axios from "axios";
// import { styled } from "@mui/system";
// import PersonIcon from "@mui/icons-material/Person";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import CloseIcon from "@mui/icons-material/Close";
// import DownloadIcon from "@mui/icons-material/Download";


// const SavingDepositWithdraw = ({ accounts }) => {
//   const [closingBranches, setClosingBranches] = useState([]);
//   const [accountOptions, setAccountOptions] = useState([]);
//   const [selectedAccount, setSelectedAccount] = useState(null);
//   const [formData, setFormData] = useState({
//     paymentBy: "",
//     txnDate: "",
//     closingBranch: "",
//     accountNumber: "",
//     memberCode: "",
//     memberName: "",
//     mobileNo: "",
//     sbPlanName: "",
//     availableBalance: "",
//     transactionFor: "",
//     remarks: "",
//     txnType: "",
//     amount: "",
//     transactionType: " ",
//     transactionDate:'',
//   });

//   const handleSubmit = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       await axios.post(
//         "https://api.vlnidhi.com/api/admin/transactions/make-transaction",
//         {
//           transactionDate: formData.transactionDate,
//           memberCode: formData.memberCode,
//           branchCode: formData.closingBranch,
//           savingAcoountNo: formData.accountNumber,
//           mobileNumber: formData.mobileNo,
//           jointHolder: formData.memberName,
//           sbPlanName: formData.sbPlanName,
//           transactionFor: formData.transactionFor,
//           transactionType: formData.txnType,
//           amount: formData.amount,
//           remarks: formData.remarks,
//           paymentBy: formData.paymentBy,
//           transactionType: formData.transactionType,

//         },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       // Handle success (e.g., show success message, reset form)
//       console.log("Transaction successful");
//     } catch (error) {
//       // Handle error (e.g., show error message)
//       console.error("Error making transaction:", error);
//     }
//   };

//   useEffect(() => {
//     const token = localStorage.getItem("token");

//     // Fetch saving accounts
//     axios
//       .get("https://api.vlnidhi.com/api/admin/saving-accounts/getAll", {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => {
//         const formattedAccountOptions = response.data.map((account) => ({
//           value: account.savingAccountNumber,
//           label: `${account.member?.memberName || "Unknown Member"} - ${account.savingAccountNumber}`,
//           accountData: account,
//         }));
//         setAccountOptions(formattedAccountOptions);
//       })
//       .catch((error) => console.error("Error fetching account numbers:", error));

//     // Fetch closing branches
//     axios
//       .get("https://api.vlnidhi.com/api/admin/getallbid", {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => setClosingBranches(response.data))
//       .catch((error) => console.error("Error fetching closing branches:", error));
//   }, []);

//   const handleAccountChange = (event) => {
//     const accountNumber = event.target.value;
//     const account = accountOptions.find((acc) => acc.value === accountNumber);
//     if (account) {
//       const { accountData } = account;
//       setSelectedAccount(accountData);

//       setFormData({
//         ...formData,
//         accountNumber: accountData.savingAccountNumber,
//         memberCode: accountData.member?.memberCode || "",
//         memberName: accountData.member?.memberName || "",
//         mobileNo: accountData.member?.mobileNo || "",
//         sbPlanName: accountData.selectPlan || "",
//         openingAmount: accountData.openingAmount || "",
//       });
//     }
//   };

//   const handleFormChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

 

//   const ImageUploadWrapper = styled(Box)(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     border: "2px solid #E0E0E0",
//     borderRadius: "8px",
//     width: "200px",
//     height: "200px",
//     backgroundColor: "#F9F9F9",
//     alignSelf: "flex-start",
//   }));

//   const UploadButton = styled(Button)(({ theme }) => ({
//     textTransform: "none",
//     marginTop: theme.spacing(2),
//     backgroundColor: "#E0E0E0",
//     alignSelf: "flex-start",
//   }));

//   const Caption = styled(Typography)(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     fontSize: "12px",
//     color: "#BDBDBD",
//     alignSelf: "flex-start",
//   }));

//   const dummyData = [
//     {
//       schemeCode: "001",
//       schemeName: "Gold Plan",
//       intRate: 4.5,
//       openingAmount: 1000,
//       avgBalance: 5000,
//       lockinBalance: 3000,
//       smsMode: "Enabled",
//       smsCharges: 10,
//       cardCharges: 20,
//       cardLimitMly: 20000,
//       cardLimitYly: 240000,
//     },
//     {
//       schemeCode: "002",
//       schemeName: "Silver Plan",
//       intRate: 3.5,
//       openingAmount: 500,
//       avgBalance: 2500,
//       lockinBalance: 1500,
//       smsMode: "Disabled",
//       smsCharges: 0,
//       cardCharges: 15,
//       cardLimitMly: 15000,
//       cardLimitYly: 180000,
//     },
//   ];

//   return (
//     <Container maxWidth="lg">
//       <Typography
//         sx={{
//           marginTop: { xs: "5%", md: "2%" },
//           fontSize: { xs: "20px", md: "24px" },
//           fontWeight: 600,
//           lineHeight: "36px",
//           textAlign: "start",
//         }}
//       >
//         Savings Deposit/Withdrawal
//       </Typography>

//       <Paper sx={{ padding: "2%", marginTop: "2%" }}>
//         <Typography
//           sx={{
//             fontSize: { xs: "18px", md: "20px" },
//             fontWeight: 500,
//             marginBottom: "2%",
//             lineHeight: "30px",
//           }}
//         >
//           Account Details
//         </Typography>

//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Txn Date*
//             </Typography>
//             <TextField
//               fullWidth
//               name="transactionDate" // Ensure name matches formData key
//               value={formData.transactionDate} // Bind value to formData
//               onChange={handleFormChange}
//               type="date"
//               // value={formData.openingDate}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Select Branch Name*
//             </Typography>
//             <FormControl fullWidth>
//               <Select
//                 name="closingBranch"
//                 value={formData.closingBranch}
//                 onChange={handleFormChange}
//                 sx={{
//                   backgroundColor: "#FFFFFF",
//                   borderRadius: "10px",
//                   "& .MuiInputBase-root": {
//                     height: { xs: "40px", md: "48px" },
//                   },
//                 }}
//               >
//                 {closingBranches.map((branch) => (
//                   <MenuItem key={branch.branchId} value={branch.branchName}>
//                     {branch.branchName}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//         <Grid item xs={12} md={6}>
//       <Typography
//         sx={{
//           width: "100%",
//           fontSize: { xs: "16px", md: "18px" },
//           marginBottom: "8px",
//         }}
//       >
//         Select Account Number*
//       </Typography>
//       <FormControl fullWidth>
//               <InputLabel id="account-number-label">Select</InputLabel>
//               <Select
//                 labelId="account-number-label"
//                 name="accountNumber"
//                 value={formData.accountNumber}
//                 onChange={handleAccountChange}
//                 sx={{
//                   backgroundColor: "#FFFFFF",
//                   borderRadius: "10px",
//                   "& .MuiInputBase-root": {
//                     height: { xs: "40px", md: "48px" },
//                   },
//                 }}
//               >
//                 {accountOptions.map((option) => (
//                   <MenuItem key={option.value} value={option.value}>
//                     {option.label}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//     </Grid>
//     <Grid item xs={12} md={6}>
//         <Typography
//           sx={{
//             width: "100%",
//             fontSize: { xs: "16px", md: "18px" },
//             marginBottom: "8px",
//           }}
//         >
//           Member Code*
//         </Typography>
//         <TextField
//               fullWidth
//               value={formData.memberCode}
//               name="memberCode"
//               InputProps={{
//                 readOnly: true,
//               }}
//           sx={{
//             backgroundColor: "#FFFFFF",
//             borderRadius: "10px",
//             "& .MuiInputBase-root": {
//               height: { xs: "40px", md: "48px" },
//             },
//           }}
//         />
//       </Grid>

//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Member Name*
//             </Typography>
//             <TextField
//               fullWidth
//               name="memberName"
//               value={formData.memberName}
//               onChange={handleFormChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Mobile Number**
//             </Typography>
//             <TextField
//               name="mobileNo"
//               value={formData.mobileNo}
//               onChange={handleFormChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               SB Plan Name*
//             </Typography>
//             <TextField
//               name="sbPlanName"
//               value={formData.sbPlanName}
//               onChange={handleFormChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>

//         {/* Save Button */}
//         <Grid container spacing={2} mt={1}>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={3}
//             container
//             direction="column"
//             alignItems="center"
//           >
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Applicant Photo
//             </Typography>
//             <ImageUploadWrapper>
//               <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
//             </ImageUploadWrapper>
//             <UploadButton variant="contained" component="label">
//               Choose File
//               <input type="file" hidden />
//             </UploadButton>
//             <Caption>Photo Size: Maximum 10MB</Caption>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={3}
//             container
//             direction="column"
//             alignItems="c"
//           >
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Signature
//             </Typography>
//             <ImageUploadWrapper>
//               {/* Removed PersonIcon for Signature */}
//             </ImageUploadWrapper>
//             <UploadButton variant="contained" component="label">
//               Choose File
//               <input type="file" hidden />
//             </UploadButton>
//             <Caption>Photo Size: Maximum 10MB</Caption>
//           </Grid>
//         </Grid>
//       </Paper>

//       <Paper sx={{ padding: "2%", marginTop: "2%" }}>
//         <Typography
//           sx={{
//             marginTop: { xs: "5%", md: "2%" },
//             fontSize: { xs: "20px", md: "24px" },
//             fontWeight: 600,
//             lineHeight: "36px",
//             textAlign: "start",
//           }}
//         >
//           Payment Details
//         </Typography>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Ava. Balance *
//             </Typography>
//             <TextField
//               fullWidth
//               name="openingAmount"
//               value={formData.openingAmount}
//               onChange={handleFormChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Transaction For*
//             </Typography>
//             <TextField
//               fullWidth
//               name="transactionFor" // Ensure name matches formData key
//               value={formData.transactionFor              } // Bind value to formData
//               onChange={handleFormChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Remarks*
//             </Typography>
//             <TextField
//               fullWidth
//               name="remarks" // Ensure name matches formData key
//               value={formData.remarks} // Bind value to formData
//               onChange={handleFormChange} // Handle change to update formData
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//   <Typography
//     sx={{
//       width: "100%",
//       fontSize: { xs: "16px", md: "18px" },
//       marginBottom: "8px",
//     }}
//   >
//     Txn. Type*
//   </Typography>
//   <FormControl fullWidth>
//     <InputLabel id="txn-type-label">Select</InputLabel>
//     <Select
//       labelId="txn-type-label"
//           name="transactionType"
//       value={formData.transactionType }
//       onChange={handleFormChange}
//       sx={{
//         backgroundColor: "#FFFFFF",
//         borderRadius: "10px",
//         "& .MuiInputBase-root": {
//           height: { xs: "40px", md: "48px" },
//         },
//       }}
//     >
//       <MenuItem value="Credit">Deposit</MenuItem>
//       <MenuItem value="Debit">Withdrawal</MenuItem>
//     </Select>
//   </FormControl>
//        </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Amount*
//             </Typography>
//             <TextField
//               fullWidth
//               name="amount" // Ensure name matches formData key
//               value={formData.amount} // Bind value to formData
//               onChange={handleFormChange} // Handle change to update formData
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//         <Typography
//           sx={{
//             width: "100%",
//             fontSize: { xs: "16px", md: "18px" },
//             marginBottom: "8px",
//           }}
//         >
//           Payment By*
//         </Typography>
//         <FormControl fullWidth>
//           <InputLabel id="paymentBy-label">Select</InputLabel>
//           <Select
//             labelId="paymentBy-label"
//             id="paymentBy"
//             name="paymentBy"
//             value={formData.paymentBy} // Controlled component value
//             onChange={handleFormChange} // Handle changes
//             sx={{
//               backgroundColor: "#FFFFFF",
//               borderRadius: "10px",
//               "& .MuiInputBase-root": {
//                 height: { xs: "40px", md: "48px" },
//               },
//             }}
//           >
//             <MenuItem value="Cash">Cash</MenuItem>
//             <MenuItem value="Check">Check</MenuItem>
//             <MenuItem value="Online">Online</MenuItem>
//             <MenuItem value="NEFT">NEFT</MenuItem>
//           </Select>
//         </FormControl>
//       </Grid>
//         </Grid>

//         <Grid container justifyContent="flex-end" sx={{ marginTop: '2%' }}>
//         <Button
//             variant="contained"
//             onClick={handleSubmit}
//             sx={{
//             width: { xs: '100%', md: '247px' },
//             height: '48px',
//             backgroundColor: '#1976d2',
//             borderRadius: '10px',
//             padding: '12px 103px',
//             fontSize: '16px',
//             fontWeight: 'bold',
//             textAlign: 'center',
//             marginTop: '2px',
//             }}
//         >
//             Save
//         </Button>
//         </Grid>

//       </Paper>
//       {/* Second Paper (Table Section) */}
//       <Paper
//         sx={{ padding: "2%", marginTop: "4%", borderRadius: "10px 10px 0 0" }}
//       >
//         <Grid style={{ display: "flex", justifyContent: "space-between" }}>
//           <Typography
//             sx={{
//               fontSize: { xs: "18px", md: "20px" },
//               fontWeight: 500,
//               marginBottom: "2%",
//             }}
//           >
//             Scheme List
//           </Typography>
//           <Button>
//             <DownloadIcon />
//           </Button>
//         </Grid>
//         <TableContainer>
//           <Table sx={{ minWidth: 650 }}>
//             <TableHead>
//               <TableRow sx={{ backgroundColor: "#413AF1" }}>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   INO
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   BRANCH
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   TXN DATE
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   A/C NO
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   CREDIT
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   DEBIT
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   BALANCE
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   PAY MODE
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   REMARKS
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   TXN ID
//                 </TableCell>
//                 <TableCell
//                   sx={{
//                     color: "white",
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: "bold",
//                     textAlign: "center",
//                   }}
//                 >
//                   USER ID
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {dummyData.map((row, index) => (
//                 <TableRow
//                   key={index}
//                   sx={{
//                     backgroundColor: index % 2 === 0 ? "#F2F6FA" : "#C1E0FB",
//                   }}
//                 >
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.schemeCode}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.schemeName}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.intRate}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.openingAmount}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.avgBalance}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.lockinBalance}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.smsMode}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.smsCharges}
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       fontSize: { xs: "10px", md: "12px" },
//                       textAlign: "center",
//                     }}
//                   >
//                     {row.cardCharges}
//                   </TableCell>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     <IconButton>
//                       <CloseIcon />
//                     </IconButton>
//                   </TableCell>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     <IconButton>
//                       <VisibilityIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Paper>
//     </Container>
//   );
// };

// export default SavingDepositWithdraw;


