///////working code
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Switch,
  Paper,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

dayjs.extend(advancedFormat);

const steps = ["Step 1", "Step 2", "Step 3"];

const stepIconStyle = {
  "&.Mui-completed": {
    color: "green",
  },
  "&.Mui-active": {
    color: "#3029D9",
  },
  "&.Mui-disabled": {
    color: "gray",
  },
};

const textFieldStyle = {
  width: "518px",
  height: "48px",
  borderRadius: "80%",
  borderColor: "#D9D9D9CC",
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
  opacity: 0.6,
};

const AddMember = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [shareAllotedOptions, setShareAllotedOptions] = useState([]);
  const [branchNameOptions, setBranchNameOptions] = useState([]);

  const [formValues, setFormValues] = useState({
    verifyWith: "",
    registrationDate: "",
    memberNameTitle: "",
    memberName: "",
    sponsorName: "",
    gender: "",
    dob: "",
    age: "",
    martialStatus: "",
    address: "",
    district: "",
    state: "",
    pincode: "",
    branchName: "",
    aadharNo: "",
    panNo: "",
    voterNo: "",
    mobileNo: "",
    emailId: "",
    occupation: "",
    education: "",
    introMemCode: "",
    nomineeAge: "",
    photo: null,
    nomineeName: "",
    nomineeRelation: "",
    nomineeAddress: "",
    nomineeDistrict: "",
    nomineeState: "",
    nomineePinCode: "",
    nomineemobileNo: "",
    noOfShare: "",
    shareAmount: "",
    remarks: "",
    memberfees: "",
  });

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  // Fetching the branch name options
  useEffect(() => {
    const fetchBranchNames = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          //   "http://localhost:9191/api/admin/getallbid",
          "https://api.vlnidhi.com/api/admin/getallbid",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const formattedBranchNames = response.data.map((branch) => ({
          id: branch.id,
          value: branch.branchName,
          label: branch.branchName,
        }));

        setBranchNameOptions(formattedBranchNames);
      } catch (error) {
        console.error("Error fetching branch names:", error);
        toast.error("Error fetching branch names");
      }
    };

    fetchBranchNames();
  }, []);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          // "http://localhost:9191/api/admin/promotors",
          "https://api.vlnidhi.com/api/admin/promotors",

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const promotors = response.data.filter(
          (option) => option.promotor === true
        );

        const formattedPromotors = promotors.map((option) => ({
          id: option.id,
          value: option.memberCode,
          label: option.memberName,
        }));

        setShareAllotedOptions(formattedPromotors);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
        toast.error("Error fetching dropdown options");
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    axios
      // .post("http://localhost:9191/api/admin/addmember", formValues, {
      .post("https://api.vlnidhi.com/api/admin/addmember", formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { memberCode } = response.data;

        toast.success("Member added successfully!", {
          position: "top-right",
          autoClose: 3000,
        });

        Swal.fire({
          title: "Success!",
          text: `Member added successfully with Member Code: ${memberCode}`,
          icon: "success",
          confirmButtonText: "OK",
        });

        localStorage.setItem("formValues", JSON.stringify(formValues));
      })
      .catch((error) => {
        console.error("Error adding member:", error);
        toast.error("Failed to add member!", {
          position: "top-right",
          autoClose: 3000,
        });
        localStorage.setItem("formValues", JSON.stringify(formValues));
      });
  };

  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            // textAlign: "left",
            mb: 1,
          }}
        >
          Add Member
        </Typography>

        <Paper
          sx={{
            width: "100%",
            p: 4,
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    sx: stepIconStyle,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "36px",
                  mb: 1,
                  mt: 2,
                }}
              >
                Member Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Verify With*
                  </Typography>
                  <TextField
                    // fullWidth
                    select
                    // label="Verify With"
                    name="verifyWith"
                    value={formValues.verifyWith}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="   WITH_AADHAR">With Aadhar</MenuItem>
                    <MenuItem value="WITHOUT_AADHAR">Without Aadhar</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Registration Date*
                  </Typography>
                  <TextField
                    name="registrationDate"
                    value={formValues.registrationDate}
                    onChange={(event) =>
                      handleDateChange("registrationDate", event.target.value)
                    }
                    type="date"
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Member Name*
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      // fullWidth
                      select
                      // label="Mr."
                      name="memberNameTitle"
                      value={formValues.memberNameTitle}
                      onChange={handleChange}
                      sx={{
                        ...textFieldStyle,
                        width: "101px",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    >
                      <MenuItem value="MR">Mr.</MenuItem>
                      <MenuItem value="MS">MS</MenuItem>
                      <MenuItem value="MRS">MRS</MenuItem>
                      <MenuItem value="Smt">Smt</MenuItem>
                      <MenuItem value="MD">MD</MenuItem>
                    </TextField>
                    <TextField
                      fullWidth
                      // label="Member Name"
                      name="memberName"
                      value={formValues.memberName}
                      onChange={handleChange}
                      sx={{
                        ...textFieldStyle,
                        width: "405px",
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Sponsor Name
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Sponsor Name"
                    name="sponsorName"
                    value={formValues.sponsorName}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Gender
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    label="Gender"
                    name="gender"
                    value={formValues.gender}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        DOB
                      </Typography>
                      <TextField
                        fullWidth
                        type="date"
                        name="dob"
                        value={formValues.dob || ""}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          handleChange(e); // Update the formValues state
                          // Calculate age based on the selected dob
                          const today = new Date();
                          const birthDate = new Date(value);
                          let age =
                            today.getFullYear() - birthDate.getFullYear();
                          const monthDifference =
                            today.getMonth() - birthDate.getMonth();
                          if (
                            monthDifference < 0 ||
                            (monthDifference === 0 &&
                              today.getDate() < birthDate.getDate())
                          ) {
                            age--;
                          }
                          // Update the formValues with the calculated age
                          setFormValues((prevState) => ({
                            ...prevState,
                            age: age,
                          }));
                        }}
                        sx={{
                          ...textFieldStyle,
                          width: "253px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: { xs: "16px", md: "18px" },
                          marginBottom: "8px",
                          ...fontStyle,
                        }}
                      >
                        Age (in years)
                      </Typography>
                      <TextField
                        fullWidth
                        name="age"
                        value={formValues.age || ""}
                        disabled
                        sx={{
                          ...textFieldStyle,
                          width: "253px",
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6}></Grid> */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Marital Status
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    // label="Marital Status"
                    name="martialStatus"
                    value={formValues.martialStatus}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="SINGLE">Single</MenuItem>
                    <MenuItem value="MARRIED">Married</MenuItem>
                    <MenuItem value="UNMARRIED">Unmarried</MenuItem>
                    <MenuItem value="DIVORCED">Divorced</MenuItem>
                    <MenuItem value="WIDOWED">Widowed</MenuItem>
                    <MenuItem value="SEPARATED">SEPARATED</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Address
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Address"
                    name="address"
                    value={formValues.address}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    District
                  </Typography>
                  <TextField
                    fullWidth
                    // label="District"
                    name="district"
                    value={formValues.district}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    State
                  </Typography>
                  <TextField
                    fullWidth
                    // label="State"
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    State
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="ANDAMAN_AND_NICOBAR_ISLANDS">
                      ANDAMAN AND NICOBAR ISLANDS
                    </MenuItem>
                    <MenuItem value="ANDHRA_PRADESH">ANDHRA PRADESH</MenuItem>
                    <MenuItem value="ARUNACHAL_PRADESH">
                      ARUNACHAL PRADESH
                    </MenuItem>
                    <MenuItem value="ASSAM">ASSAM</MenuItem>
                    <MenuItem value="BIHAR">BIHAR</MenuItem>
                    <MenuItem value="CHANDIGARH">CHANDIGARH</MenuItem>
                    <MenuItem value="CHHATTISGARH">CHHATTISGARH</MenuItem>
                    <MenuItem value="DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU">
                      DADRA AND NAGAR HAVELI AND DAMAN AND DIU
                    </MenuItem>
                    <MenuItem value="DELHI">DELHI</MenuItem>
                    <MenuItem value="GOA">GOA</MenuItem>
                    <MenuItem value="GUJARAT">GUJARAT</MenuItem>
                    <MenuItem value="HARYANA">HARYANA</MenuItem>
                    <MenuItem value="HIMACHAL_PRADESH">
                      HIMACHAL PRADESH
                    </MenuItem>
                    <MenuItem value="JAMMU_AND_KASHMIR">
                      JAMMU AND KASHMIR
                    </MenuItem>
                    <MenuItem value="JHARKHAND">JHARKHAND</MenuItem>
                    <MenuItem value="KARNATAKA">KARNATAKA</MenuItem>
                    <MenuItem value="KERALA">KERALA</MenuItem>
                    <MenuItem value="LADAKH">LADAKH</MenuItem>
                    <MenuItem value="LAKSHADWEEP">LAKSHADWEEP</MenuItem>
                    <MenuItem value="MADHYA_PRADESH">MADHYA PRADESH</MenuItem>
                    <MenuItem value="MAHARASHTRA">MAHARASHTRA</MenuItem>
                    <MenuItem value="MANIPUR">MANIPUR</MenuItem>
                    <MenuItem value="MEGHALAYA">MEGHALAYA</MenuItem>
                    <MenuItem value="MIZORAM">MIZORAM</MenuItem>
                    <MenuItem value="NAGALAND">NAGALAND</MenuItem>
                    <MenuItem value="ODISHA">ODISHA</MenuItem>
                    <MenuItem value="PUDUCHERRY">PUDUCHERRY</MenuItem>
                    <MenuItem value="PUNJAB">PUNJAB</MenuItem>
                    <MenuItem value="RAJASTHAN">RAJASTHAN</MenuItem>
                    <MenuItem value="SIKKIM">SIKKIM</MenuItem>
                    <MenuItem value="TAMIL_NADU">TAMIL NADU</MenuItem>
                    <MenuItem value="TELANGANA">TELANGANA</MenuItem>
                    <MenuItem value="TRIPURA">TRIPURA</MenuItem>
                    <MenuItem value="UTTARAKHAND">UTTARAKHAND</MenuItem>
                    <MenuItem value="UTTAR_PRADESH">UTTAR PRADESH</MenuItem>
                    <MenuItem value="WEST_BENGAL">WEST BENGAL</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Pin Code
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Pin Code"
                    name="pincode"
                    value={formValues.pincode}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Branch Name
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Branch Name"
                    name="branchName"
                    value={formValues.branchName}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Branch Name
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    name="branchName"
                    value={formValues.branchName}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {branchNameOptions.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Aadhar Number
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      fullWidth
                      name="aadharNo"
                      value={formValues.aadharNo}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        // Allow only digits and limit the input to 10 characters
                        if (/^\d{0,12}$/.test(value)) {
                          handleChange(e); // Update the formValues state
                        }
                      }}
                      sx={{
                        ...textFieldStyle,
                        width:'402px'
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                        inputProps: {
                          maxLength: 12, // Limit input to 10 digits
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        background: "#3029D9",
                        width: "104px",
                        height: "48px",
                        borderRadius: "10px",
                      }}
                    >
                      Verify
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Pan Number
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      fullWidth
                      name="panNo"
                      value={formValues.panNo}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        // Allow only alphanumeric characters and limit the input to 10 characters
                        if (/^[A-Z0-9]{0,10}$/.test(value)) {
                          handleChange(e); // Update the formValues state
                        }
                      }}
                      sx={{
                        ...textFieldStyle,
                        width:'402px'
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                        inputProps: {
                          maxLength: 10, // Limit input to 10 characters
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        background: "#3029D9",
                        width: "104px",
                        height: "48px",
                        borderRadius: "10px",
                      }}
                    >
                      Verify
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Voter Number
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Voter Number"
                    name="voterNo"
                    value={formValues.voterNo}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      // Allow only alphanumeric characters and limit the input to 10 characters
                      if (/^[A-Z0-9]{0,10}$/.test(value)) {
                        handleChange(e); // Update the formValues state
                      }
                    }}
                    sx={{
                      ...textFieldStyle,
                      
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      inputProps: {
                        maxLength: 10, // Limit input to 10 characters
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="mobileNo"
                    value={formValues.mobileNo}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      // Allow only digits and limit the input to 10 characters
                      if (/^\d{0,10}$/.test(value)) {
                        handleChange(e); // Update the formValues state
                      }
                    }}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      inputProps: {
                        maxLength: 10, // Limit input to 10 digits
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Email ID
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Email ID"
                    name="emailId"
                    value={formValues.emailId}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Occupation
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Occupation"
                    name="occupation"
                    value={formValues.occupation}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Education
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Education"
                    name="education"
                    value={formValues.education}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Intro. M. Name
                  </Typography>
                  <TextField
                    fullWidth
                    // label="Intro. M. Name"
                    name="introMemCode"
                    value={formValues.introMemCode}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    container
                    direction="column"
                    // alignItems="center"
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { xs: "16px", md: "18px" },
                        marginBottom: "8px",
                        ...fontStyle,
                      }}
                    >
                      Photo(With Aadhar)
                    </Typography>

                    <ImageUploadWrapper>
                      <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
                    </ImageUploadWrapper>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          {/* ----------------------------------------------------------------------------------------------------------------------- */}
          {/* Step 2 details */}
          {activeStep === 1 && (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "36px",
                  mb: 1,
                  mt: 2,
                }}
              >
                Nominee Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Nominee Name*
                  </Typography>
                  <TextField
                    fullWidth
                    name="nomineeName"
                    value={formValues.nomineeName}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Nominee Relation
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    name="nomineeRelation"
                    value={formValues.nomineeRelation}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="FATHER">FATHER</MenuItem>
                    <MenuItem value="MOTHER">MOTHER</MenuItem>
                    <MenuItem value="BROTHER">BROTHER</MenuItem>
                    <MenuItem value="SISTER">SISTER</MenuItem>
                    <MenuItem value="HUSBAND">HUSBAND</MenuItem>
                    <MenuItem value="WIFE">WIFE</MenuItem>
                    <MenuItem value="SON">SON</MenuItem>
                    <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
                    <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
                    <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
                    <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
                    <MenuItem value="GRANDSON">GRANDSON</MenuItem>
                    <MenuItem value="OTHER">OTHER</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Nominee Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="nomineemobileNo"
                    value={formValues.nomineemobileNo}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Nominee Age
                  </Typography>
                  <TextField
                    fullWidth
                    name="nomineeAge"
                    value={formValues.nomineeAge}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* ----------------------------------------------------------------------------------------------------------------------- */}
          {/* Step 2 details */}
          {activeStep === 2 && (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "36px",
                  mb: 1,
                  mt: 2,
                }}
              >
                Payment Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Member Fees(If Any)*
                  </Typography>
                  <TextField
                    fullWidth
                    name="memberfees"
                    value={formValues.memberfees}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Share Alloted Form*
                  </Typography>

                  <TextField
                    fullWidth
                    select
                    name="shareAllotedForm"
                    value={formValues.shareAllotedForm}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {shareAllotedOptions.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    No. Of Share*
                  </Typography>
                  <TextField
                    fullWidth
                    name="noOfShare"
                    value={formValues.noOfShare}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleChange(e); // Update the formValues state
                      const shareAmount = value * 10; // Calculate shareAmount based on noOfShare
                      setFormValues((prevState) => ({
                        ...prevState,
                        shareAmount: shareAmount,
                      }));
                    }}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Share Amount*
                  </Typography>
                  <TextField
                    fullWidth
                    name="shareAmount"
                    value={formValues.shareAmount}
                    disabled
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Payment By*
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    name="paymentBy"
                    value={formValues.paymentBy}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="CASH">CASH</MenuItem>
                    <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                    <MenuItem value="ONLINE">ONLINE</MenuItem>
                    <MenuItem value="NEFT">NEFT</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Remarks
                  </Typography>
                  <TextField
                    fullWidth
                    name="remarks"
                    value={formValues.remarks}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                      ...fontStyle,
                    }}
                  >
                    Member Status
                  </Typography>
                  <Switch sx={{ transform: "scale(1.5)" }} />
                  {/* <TextField
                    fullWidth
                    name="remarks"
                    value={formValues.remarks}
                    onChange={handleChange}
                    sx={{
                      ...textFieldStyle,
                    }}
                  /> */}
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Navigation buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: "2%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                gap: "2%",
              }}
            >
              {activeStep === 2 ? (
                <>
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: "250px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      // Logic for printing
                      window.print(); // Example for print
                    }}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: "250px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      background: "#E0C042",
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      background: "#3029D9",
                      width: "250px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      width: "157px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                      background: "#3029D9",
                      width: "157px",
                      height: "48px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </LocalizationProvider>
    </Box>
  );
};

export default AddMember;











// ///////working code
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   TextField,
//   Button,
//   MenuItem,
//   Grid,
//   Stepper,
//   Step,
//   StepLabel,
//   Typography,
//   Switch,
//   Paper,
//   styled,
// } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import advancedFormat from "dayjs/plugin/advancedFormat";
// import PersonIcon from "@mui/icons-material/Person";
// import Swal from "sweetalert2";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// dayjs.extend(advancedFormat);

// const steps = ["Step 1", "Step 2", "Step 3"];

// const stepIconStyle = {
//   "&.Mui-completed": {
//     color: "green",
//   },
//   "&.Mui-active": {
//     color: "#3029D9",
//   },
//   "&.Mui-disabled": {
//     color: "gray",
//   },
// };

// const textFieldStyle = {
//   width: "500px",
//   height: "48px",
//   borderRadius: "80%",
//   borderColor: "#D9D9D9CC",
//   backgroundColor: "#FFFFFF",
//   "& .MuiInputBase-root": {
//     height: "48px",
//   },
// };

// const fontStyle = {
//   fontFamily: "Poppins, sans-serif",
//   fontSize: "14px",
//   opacity: 0.6,
// };

// const AddMember = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const [shareAllotedOptions, setShareAllotedOptions] = useState([]);
//   const [branchNameOptions, setBranchNameOptions] = useState([]);

//   const [formValues, setFormValues] = useState({
//     verifyWith: "",
//     registrationDate: "",
//     memberNameTitle: "",
//     memberName: "",
//     sponsorName: "",
//     gender: "",
//     dob: "",
//     age: "",
//     martialStatus: "",
//     address: "",
//     district: "",
//     state: "",
//     pincode: "",
//     branchName: "",
//     aadharNo: "",
//     panNo: "",
//     voterNo: "",
//     mobileNo: "",
//     emailId: "",
//     occupation: "",
//     education: "",
//     introMemCode: "",
//     nomineeAge: "",
//     photo: null,
//     nomineeName: "",
//     nomineeRelation: "",
//     nomineeAddress: "",
//     nomineeDistrict: "",
//     nomineeState: "",
//     nomineePinCode: "",
//     nomineemobileNo: "",
//     noOfShare: "",
//     shareAmount: "",
//     remarks: "",
//     memberfees: "",
//   });

//   const handleNext = () => {
//     if (activeStep === steps.length - 1) {
//       handleSubmit();
//     } else {
//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     }
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormValues({ ...formValues, [name]: value });
//   };

//   const handleDateChange = (name, value) => {
//     setFormValues({ ...formValues, [name]: value });
//   };

//   // Fetching the branch name options
//   useEffect(() => {
//     const fetchBranchNames = async () => {
//       const token = localStorage.getItem("token");

//       try {
//         const response = await axios.get(
//           // "http://localhost:9191/api/admin/getallbid",
//           "https://api.vlnidhi.com/api/admin/getallbid",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         const formattedBranchNames = response.data.map((branch) => ({
//           id: branch.id,
//           value: branch.branchName,
//           label: branch.branchName,
//         }));

//         setBranchNameOptions(formattedBranchNames);
//       } catch (error) {
//         console.error("Error fetching branch names:", error);
//         toast.error("Error fetching branch names");
//       }
//     };

//     fetchBranchNames();
//   }, []);

//   useEffect(() => {
//     const fetchDropdownOptions = async () => {
//       const token = localStorage.getItem("token");

//       try {
//         const response = await axios.get(
//           // "http://localhost:9191/api/admin/promotors",
//           "https://api.vlnidhi.com/api/admin/promotors",

//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         const promotors = response.data.filter(
//           (option) => option.promotor === true
//         );

//         const formattedPromotors = promotors.map((option) => ({
//           id: option.id,
//           value: option.memberCode,
//           label: option.memberName,
//         }));

//         setShareAllotedOptions(formattedPromotors);
//       } catch (error) {
//         console.error("Error fetching dropdown options:", error);
//         toast.error("Error fetching dropdown options");
//       }
//     };

//     fetchDropdownOptions();
//   }, []);

//   const handleSubmit = () => {
//     const token = localStorage.getItem("token");
//     axios
//       // .post("http://localhost:9191/api/admin/addmember", formValues, {
//       .post("https://api.vlnidhi.com/api/admin/addmember", formValues, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((response) => {
//         const { memberCode } = response.data;

//         toast.success("Member added successfully!", {
//           position: "top-right",
//           autoClose: 3000,
//         });

//         Swal.fire({
//           title: "Success!",
//           text: `Member added successfully with Member Code: ${memberCode}`,
//           icon: "success",
//           confirmButtonText: "OK",
//         });

//         localStorage.setItem("formValues", JSON.stringify(formValues));
//       })
//       .catch((error) => {
//         console.error("Error adding member:", error);
//         toast.error("Failed to add member!", {
//           position: "top-right",
//           autoClose: 3000,
//         });
//         localStorage.setItem("formValues", JSON.stringify(formValues));
//       });
//   };

//   const ImageUploadWrapper = styled(Box)(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     border: "2px solid #E0E0E0",
//     borderRadius: "8px",
//     width: "200px",
//     height: "200px",
//     backgroundColor: "#F9F9F9",
//     alignSelf: "flex-start",
//   }));

//   const UploadButton = styled(Button)(({ theme }) => ({
//     textTransform: "none",
//     marginTop: theme.spacing(2),
//     backgroundColor: "#E0E0E0",
//     alignSelf: "flex-start",
//   }));

//   const Caption = styled(Typography)(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     fontSize: "12px",
//     color: "#BDBDBD",
//     alignSelf: "flex-start",
//   }));
//   return (
//     <Box>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <Typography
//           sx={{
//             fontFamily: "Poppins",
//             fontSize: "24px",
//             fontWeight: 600,
//             lineHeight: "36px",
//             // textAlign: "left",
//             mb: 1,
//           }}
//         >
//           Add Member
//         </Typography>

//         <Paper
//           sx={{
//             width: "100%",
//             p: 4,
//             borderRadius: "20px",
//             boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//           }}
//         >
//           <Stepper activeStep={activeStep}>
//             {steps.map((label, index) => (
//               <Step key={label}>
//                 <StepLabel
//                   StepIconProps={{
//                     sx: stepIconStyle,
//                   }}
//                 >
//                   {label}
//                 </StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//           {activeStep === 0 && (
//             <Box>
//               <Typography
//                 sx={{
//                   fontFamily: "Poppins",
//                   fontSize: "20px",
//                   fontWeight: 600,
//                   lineHeight: "36px",
//                   mb: 1,
//                   mt: 2,
//                 }}
//               >
//                 Member Details
//               </Typography>
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Verify With*
//                   </Typography>
//                   <TextField
//                     // fullWidth
//                     select
//                     // label="Verify With"
//                     name="verifyWith"
//                     value={formValues.verifyWith}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="   WITH_AADHAR">With Aadhar</MenuItem>
//                     <MenuItem value="WITHOUT_AADHAR">Without Aadhar</MenuItem>
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Registration Date*
//                   </Typography>
//                   <TextField
//                     name="registrationDate"
//                     value={formValues.registrationDate}
//                     onChange={(event) =>
//                       handleDateChange("registrationDate", event.target.value)
//                     }
//                     type="date"
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Member Name*
//                   </Typography>
//                   <Box sx={{ display: "flex", gap: 2 }}>
//                     <TextField
//                       // fullWidth
//                       select
//                       // label="Mr."
//                       name="memberNameTitle"
//                       value={formValues.memberNameTitle}
//                       onChange={handleChange}
//                       sx={{
//                         ...textFieldStyle,
//                         width: "101px",
//                       }}
//                       InputProps={{
//                         style: {
//                           borderRadius: "10px",
//                         },
//                       }}
//                     >
//                       <MenuItem value="MR">Mr.</MenuItem>
//                       <MenuItem value="MS">MS</MenuItem>
//                       <MenuItem value="MRS">MRS</MenuItem>
//                       <MenuItem value="Smt">Smt</MenuItem>
//                       <MenuItem value="MD">MD</MenuItem>
//                     </TextField>
//                     <TextField
//                       fullWidth
//                       // label="Member Name"
//                       name="memberName"
//                       value={formValues.memberName}
//                       onChange={handleChange}
//                       sx={{
//                         ...textFieldStyle,
//                         width: "470px",
//                       }}
//                       InputProps={{
//                         style: {
//                           borderRadius: "10px",
//                         },
//                       }}
//                     />
//                   </Box>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Sponsor Name
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Sponsor Name"
//                     name="sponsorName"
//                     value={formValues.sponsorName}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Gender
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     label="Gender"
//                     name="gender"
//                     value={formValues.gender}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="MALE">Male</MenuItem>
//                     <MenuItem value="FEMALE">Female</MenuItem>
//                     <MenuItem value="OTHER">Other</MenuItem>
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Box sx={{ display: "flex", gap: 2 }}>
//                     <Box>
//                       <Typography
//                         sx={{
//                           width: "100%",
//                           fontSize: { xs: "16px", md: "18px" },
//                           marginBottom: "8px",
//                           ...fontStyle,
//                         }}
//                       >
//                         DOB
//                       </Typography>
//                       <TextField
//                         fullWidth
//                         type="date"
//                         name="dob"
//                         value={formValues.dob || ""}
//                         onChange={(e) => handleChange(e)}
//                         sx={{
//                           ...textFieldStyle,
//                           width: "280px",
//                         }}
//                         InputProps={{
//                           style: {
//                             borderRadius: "10px",
//                           },
//                         }}
//                       />
//                     </Box>
//                     <Box>
//                       <Typography
//                         sx={{
//                           width: "100%",
//                           fontSize: { xs: "16px", md: "18px" },
//                           marginBottom: "8px",
//                           ...fontStyle,
//                         }}
//                       >
//                         Age
//                       </Typography>
//                       <TextField
//                         fullWidth
//                         // label="Age"
//                         name="age"
//                         value={formValues.age}
//                         onChange={handleChange}
//                         sx={{
//                           ...textFieldStyle,
//                           width: "280px",
//                         }}
//                         InputProps={{
//                           style: {
//                             borderRadius: "10px",
//                           },
//                         }}
//                       />
//                     </Box>
//                   </Box>
//                 </Grid>
//                 {/* <Grid item xs={12} sm={6}></Grid> */}
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Marital Status
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     // label="Marital Status"
//                     name="martialStatus"
//                     value={formValues.martialStatus}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="SINGLE">Single</MenuItem>
//                     <MenuItem value="MARRIED">Married</MenuItem>
//                     <MenuItem value="UNMARRIED">Unmarried</MenuItem>
//                     <MenuItem value="DIVORCED">Divorced</MenuItem>
//                     <MenuItem value="WIDOWED">Widowed</MenuItem>
//                     <MenuItem value="SEPARATED">SEPARATED</MenuItem>
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Address
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Address"
//                     name="address"
//                     value={formValues.address}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     District
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="District"
//                     name="district"
//                     value={formValues.district}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 {/* <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     State
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="State"
//                     name="state"
//                     value={formValues.state}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid> */}
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     State
//                   </Typography>
//                   <TextField
//                     select
//                     fullWidth
//                     name="state"
//                     value={formValues.state}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="ANDAMAN_AND_NICOBAR_ISLANDS">
//                       ANDAMAN AND NICOBAR ISLANDS
//                     </MenuItem>
//                     <MenuItem value="ANDHRA_PRADESH">ANDHRA PRADESH</MenuItem>
//                     <MenuItem value="ARUNACHAL_PRADESH">
//                       ARUNACHAL PRADESH
//                     </MenuItem>
//                     <MenuItem value="ASSAM">ASSAM</MenuItem>
//                     <MenuItem value="BIHAR">BIHAR</MenuItem>
//                     <MenuItem value="CHANDIGARH">CHANDIGARH</MenuItem>
//                     <MenuItem value="CHHATTISGARH">CHHATTISGARH</MenuItem>
//                     <MenuItem value="DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU">
//                       DADRA AND NAGAR HAVELI AND DAMAN AND DIU
//                     </MenuItem>
//                     <MenuItem value="DELHI">DELHI</MenuItem>
//                     <MenuItem value="GOA">GOA</MenuItem>
//                     <MenuItem value="GUJARAT">GUJARAT</MenuItem>
//                     <MenuItem value="HARYANA">HARYANA</MenuItem>
//                     <MenuItem value="HIMACHAL_PRADESH">
//                       HIMACHAL PRADESH
//                     </MenuItem>
//                     <MenuItem value="JAMMU_AND_KASHMIR">
//                       JAMMU AND KASHMIR
//                     </MenuItem>
//                     <MenuItem value="JHARKHAND">JHARKHAND</MenuItem>
//                     <MenuItem value="KARNATAKA">KARNATAKA</MenuItem>
//                     <MenuItem value="KERALA">KERALA</MenuItem>
//                     <MenuItem value="LADAKH">LADAKH</MenuItem>
//                     <MenuItem value="LAKSHADWEEP">LAKSHADWEEP</MenuItem>
//                     <MenuItem value="MADHYA_PRADESH">MADHYA PRADESH</MenuItem>
//                     <MenuItem value="MAHARASHTRA">MAHARASHTRA</MenuItem>
//                     <MenuItem value="MANIPUR">MANIPUR</MenuItem>
//                     <MenuItem value="MEGHALAYA">MEGHALAYA</MenuItem>
//                     <MenuItem value="MIZORAM">MIZORAM</MenuItem>
//                     <MenuItem value="NAGALAND">NAGALAND</MenuItem>
//                     <MenuItem value="ODISHA">ODISHA</MenuItem>
//                     <MenuItem value="PUDUCHERRY">PUDUCHERRY</MenuItem>
//                     <MenuItem value="PUNJAB">PUNJAB</MenuItem>
//                     <MenuItem value="RAJASTHAN">RAJASTHAN</MenuItem>
//                     <MenuItem value="SIKKIM">SIKKIM</MenuItem>
//                     <MenuItem value="TAMIL_NADU">TAMIL NADU</MenuItem>
//                     <MenuItem value="TELANGANA">TELANGANA</MenuItem>
//                     <MenuItem value="TRIPURA">TRIPURA</MenuItem>
//                     <MenuItem value="UTTARAKHAND">UTTARAKHAND</MenuItem>
//                     <MenuItem value="UTTAR_PRADESH">UTTAR PRADESH</MenuItem>
//                     <MenuItem value="WEST_BENGAL">WEST BENGAL</MenuItem>
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Pin Code
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Pin Code"
//                     name="pincode"
//                     value={formValues.pincode}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 {/* <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Branch Name
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Branch Name"
//                     name="branchName"
//                     value={formValues.branchName}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid> */}
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Branch Name
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     name="branchName"
//                     value={formValues.branchName}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     {branchNameOptions.map((option) => (
//                       <MenuItem key={option.id} value={option.value}>
//                         {option.label}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Aadhar Number
//                   </Typography>
//                   <Box sx={{ display: "flex", gap: 2 }}>
//                     <TextField
//                       // fullWidth
//                       // label="Aadhar Number"
//                       name="aadharNo"
//                       value={formValues.aadharNo}
//                       onChange={handleChange}
//                       sx={{
//                         ...textFieldStyle,
//                         width: "460px",
//                       }}
//                       InputProps={{
//                         style: {
//                           borderRadius: "10px",
//                         },
//                       }}
//                     />
//                     <Button
//                       variant="contained"
//                       sx={{
//                         textTransform: "none",
//                         fontFamily: "Poppins",
//                         background: "#3029D9",
//                         width: "104px",
//                         height: "48px",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       Verify
//                     </Button>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Pan Number
//                   </Typography>
//                   <Box sx={{ display: "flex", gap: 2 }}>
//                     <TextField
//                       fullWidth
//                       // label="Pan Number"
//                       name="panNo"
//                       value={formValues.panNo}
//                       onChange={handleChange}
//                       sx={{
//                         ...textFieldStyle,
//                         width: "460px",
//                       }}
//                       InputProps={{
//                         style: {
//                           borderRadius: "10px",
//                         },
//                       }}
//                     />
//                     <Button
//                       variant="contained"
//                       sx={{
//                         textTransform: "none",
//                         fontFamily: "Poppins",
//                         background: "#3029D9",
//                         width: "104px",
//                         height: "48px",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       Verify
//                     </Button>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Voter Number
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Voter Number"
//                     name="voterNo"
//                     value={formValues.voterNo}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Mobile Number
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Mobile Number"
//                     name="mobileNo"
//                     value={formValues.mobileNo}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Email ID
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Email ID"
//                     name="emailId"
//                     value={formValues.emailId}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Occupation
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Occupation"
//                     name="occupation"
//                     value={formValues.occupation}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Education
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Education"
//                     name="education"
//                     value={formValues.education}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Intro. M. Name
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     // label="Intro. M. Name"
//                     name="introMemCode"
//                     value={formValues.introMemCode}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Grid
//                     item
//                     xs={12}
//                     sm={6}
//                     md={3}
//                     container
//                     direction="column"
//                     // alignItems="center"
//                   >
//                     <Typography
//                       sx={{
//                         width: "100%",
//                         fontSize: { xs: "16px", md: "18px" },
//                         marginBottom: "8px",
//                         ...fontStyle,
//                       }}
//                     >
//                       Photo(With Aadhar)
//                     </Typography>

//                     <ImageUploadWrapper>
//                       <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
//                     </ImageUploadWrapper>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Box>
//           )}
//           {/* ----------------------------------------------------------------------------------------------------------------------- */}
//           {/* Step 2 details */}
//           {activeStep === 1 && (
//             <Box>
//               <Typography
//                 sx={{
//                   fontFamily: "Poppins",
//                   fontSize: "20px",
//                   fontWeight: 600,
//                   lineHeight: "36px",
//                   mb: 1,
//                   mt: 2,
//                 }}
//               >
//                 Nominee Details
//               </Typography>
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Nominee Name*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="nomineeName"
//                     value={formValues.nomineeName}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Nominee Relation
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     name="nomineeRelation"
//                     value={formValues.nomineeRelation}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="FATHER">FATHER</MenuItem>
//                     <MenuItem value="MOTHER">MOTHER</MenuItem>
//                     <MenuItem value="BROTHER">BROTHER</MenuItem>
//                     <MenuItem value="SISTER">SISTER</MenuItem>
//                     <MenuItem value="HUSBAND">HUSBAND</MenuItem>
//                     <MenuItem value="WIFE">WIFE</MenuItem>
//                     <MenuItem value="SON">SON</MenuItem>
//                     <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
//                     <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
//                     <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
//                     <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
//                     <MenuItem value="GRANDSON">GRANDSON</MenuItem>
//                     <MenuItem value="OTHER">OTHER</MenuItem>
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Nominee Mobile Number
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="nomineemobileNo"
//                     value={formValues.nomineemobileNo}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Nominee Age
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="nomineeAge"
//                     value={formValues.nomineeAge}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           )}

//           {/* ----------------------------------------------------------------------------------------------------------------------- */}
//           {/* Step 2 details */}
//           {activeStep === 2 && (
//             <Box>
//               <Typography
//                 sx={{
//                   fontFamily: "Poppins",
//                   fontSize: "20px",
//                   fontWeight: 600,
//                   lineHeight: "36px",
//                   mb: 1,
//                   mt: 2,
//                 }}
//               >
//                 Payment Details
//               </Typography>
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Member Fees(If Any)*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="memberfees"
//                     value={formValues.memberfees}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Share Alloted Form*
//                   </Typography>

//                   <TextField
//                     fullWidth
//                     select
//                     name="shareAllotedForm"
//                     value={formValues.shareAllotedForm}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     {shareAllotedOptions.map((option) => (
//                       <MenuItem key={option.id} value={option.value}>
//                         {option.label}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     No. Of Share*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="noOfShare"
//                     value={formValues.noOfShare}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Share Amount*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="shareAmount"
//                     value={formValues.shareAmount}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Payment By*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     name="paymentBy"
//                     value={formValues.paymentBy}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="CASH">CASH</MenuItem>
//                     <MenuItem value="CHEQUE">CHEQUE</MenuItem>
//                     <MenuItem value="ONLINE">ONLINE</MenuItem>
//                     <MenuItem value="NEFT">NEFT</MenuItem>
//                   </TextField>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Remarks
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     name="remarks"
//                     value={formValues.remarks}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                       ...fontStyle,
//                     }}
//                   >
//                     Member Status
//                   </Typography>
//                   <Switch sx={{ transform: "scale(1.5)" }} />
//                   {/* <TextField
//                     fullWidth
//                     name="remarks"
//                     value={formValues.remarks}
//                     onChange={handleChange}
//                     sx={{
//                       ...textFieldStyle,
//                     }}
//                   /> */}
//                 </Grid>
//               </Grid>
//             </Box>
//           )}

//           {/* Navigation buttons */}
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "flex-end",
//               mt: 3,
//               gap: "2%",
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 mt: 3,
//                 gap: "2%",
//               }}
//             >
//               {activeStep === 2 ? (
//                 <>
//                   <Button
//                     variant="outlined"
//                     disabled={activeStep === 0}
//                     onClick={handleBack}
//                     sx={{
//                       textTransform: "none",
//                       fontFamily: "Poppins",
//                       width: "250px",
//                       height: "48px",
//                       borderRadius: "10px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Previous
//                   </Button>
//                   <Button
//                     variant="contained"
//                     onClick={() => {
//                       // Logic for printing
//                       window.print(); // Example for print
//                     }}
//                     sx={{
//                       textTransform: "none",
//                       fontFamily: "Poppins",
//                       width: "250px",
//                       height: "48px",
//                       borderRadius: "10px",
//                       fontWeight: "bold",
//                       background: "#E0C042",
//                     }}
//                   >
//                     Print
//                   </Button>
//                   <Button
//                     variant="contained"
//                     onClick={handleSubmit}
//                     sx={{
//                       textTransform: "none",
//                       fontFamily: "Poppins",
//                       background: "#3029D9",
//                       width: "250px",
//                       height: "48px",
//                       borderRadius: "10px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Save
//                   </Button>
//                 </>
//               ) : (
//                 <>
//                   <Button
//                     variant="outlined"
//                     disabled={activeStep === 0}
//                     onClick={handleBack}
//                     sx={{
//                       textTransform: "none",
//                       fontFamily: "Poppins",
//                       width: "157px",
//                       height: "48px",
//                       borderRadius: "10px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Previous
//                   </Button>
//                   <Button
//                     variant="contained"
//                     onClick={handleNext}
//                     sx={{
//                       textTransform: "none",
//                       fontFamily: "Poppins",
//                       background: "#3029D9",
//                       width: "157px",
//                       height: "48px",
//                       borderRadius: "10px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {activeStep === steps.length - 1 ? "Finish" : "Next"}
//                   </Button>
//                 </>
//               )}
//             </Box>
//           </Box>
//         </Paper>
//       </LocalizationProvider>
//     </Box>
//   );
// };

// export default AddMember;
