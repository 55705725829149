import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Swal from 'sweetalert2';

const SMSSearchSaving = () => {
  const [date, setDate] = useState('');
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [reload, setReload] = useState(false); // State to trigger data reload
  const [searchPerformed, setSearchPerformed] = useState(false); // State to track if search was performed

  const token = localStorage.getItem('token');

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.vlnidhi.com/api/admin/saving-accounts/failed-sms-deductions/${date}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setSearchPerformed(true); // Mark that search was performed
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (date && reload) {
      fetchData();
    }
  }, [date, reload]); // Reload data when date changes or reload state updates

  const handleSearch = () => {
    fetchData(); // Trigger fetch on search
  };

  const handleRetry = async () => {
    try {
      await axios.post(
        'https://api.vlnidhi.com/api/admin/saving-accounts/retry-sms',
        selectedItems, // Send only the selectedItems array
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        title: 'Success!',
        text: 'Charges have been retried successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setSelectedItems([]); // Clear selected items after retry
      setReload(prev => !prev); // Toggle reload state to refresh data
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was an error retrying the charges. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.error('Error retrying charges:', error);
    }
  };

  const handleCheckboxChange = (accountNumber) => {
    setSelectedItems(prev =>
      prev.includes(accountNumber) 
        ? prev.filter(number => number !== accountNumber) 
        : [...prev, accountNumber]
    );
  };

  return (
    <Box sx={{ 
      padding: '20px', 
      borderRadius: '20px', 
      backgroundColor: '#fff', 
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
     
      margin: 'auto',
      mt: 4,
    }}>

    <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            // textAlign: "left",
            mb: 1,
          }}
        >
        SMS Charges Deduction
        </Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Search Box
      </Typography>
      
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
          <Typography sx={{ mb: 1 }}>As On Date:</Typography>
          <TextField
            variant="outlined"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            sx={{ 
              width: '490px',
              height: '55px', 
              '& input': { padding: '8px' } 
            }}
          />
        </Box>

        <Button 
          variant="contained" 
          sx={{ 
            height: '40px', 
            backgroundColor: '#D0B24F', 
            color: '#fff',
            '&:hover': { backgroundColor: '#B89B3E' },
            alignSelf: 'flex-end',
            mb:2,
          }}
          onClick={handleSearch}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </Box>

      {searchPerformed && data.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#5865F2', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Select</TableCell>
                  <TableCell sx={{ color: '#fff' }}>A/C No.</TableCell>
                  <TableCell sx={{ color: '#fff' }}>A/C Holder Name</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Member Code</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Balance</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Quarter</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Service Charges</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.accountNumber}>
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.includes(row.accountNumber)}
                        onChange={() => handleCheckboxChange(row.accountNumber)}
                      />
                    </TableCell>
                    <TableCell>{row.accountNumber}</TableCell>
                    <TableCell>{row.accountHolderName}</TableCell>
                    <TableCell>{row.memberCode}</TableCell>
                    <TableCell>{row.balance}</TableCell>
                    <TableCell>{row.quarter}</TableCell>
                    <TableCell>{row.smsCharges}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              sx={{ 
                backgroundColor: '#D0B24F', 
                color: '#fff',
                '&:hover': { backgroundColor: '#B89B3E' },
                borderRadius: '12px'
              }}
              onClick={handleRetry}
            >
              Deduct Charges
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SMSSearchSaving;





// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';

// const SMSSearchSaving = () => {
//   const [date, setDate] = useState('');
//   const [data, setData] = useState([]);
//   const [selectedItems, setSelectedItems] = useState([]);

//   const token = localStorage.getItem('token');

//   const handleSearch = async () => {
//     try {
//       const response = await axios.get(`https://api.vlnidhi.com/api/admin/saving-accounts/failed-sms-deductions/${date}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const handleRetry = async () => {
//     // Send the selected account numbers in the payload
//     try {
//       await axios.post(
//         'https://api.vlnidhi.com/api/admin/saving-accounts/retry-sms',
//         selectedItems, // Send only the selectedItems array
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log('Retry successful');
//     } catch (error) {
//       console.error('Error retrying charges:', error);
//     }
//   };

//   const handleCheckboxChange = (accountNumber) => {
//     setSelectedItems(prev =>
//       prev.includes(accountNumber) 
//         ? prev.filter(number => number !== accountNumber) 
//         : [...prev, accountNumber]
//     );
//   };

//   return (
//     <Box sx={{ 
//       width: '100%', 
//       padding: '20px', 
//       borderRadius: '20px', 
//       backgroundColor: '#fff', 
//       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       maxWidth: '1200px',
//       margin: 'auto',
//       mt: 4,
//     }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Search Box
//       </Typography>
      
//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
//           <Typography sx={{ mb: 1 }}>As On Date:</Typography>
//           <TextField
//             variant="outlined"
//             type="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//             sx={{ 
//               width: '200px',
//               height: '40px', 
//               '& input': { padding: '8px' } 
//             }}
//           />
//         </Box>

//         <Button 
//           variant="contained" 
//           sx={{ 
//             height: '40px', 
//             backgroundColor: '#D0B24F', 
//             color: '#fff',
//             '&:hover': { backgroundColor: '#B89B3E' },
//             alignSelf: 'flex-end'
//           }}
//           onClick={handleSearch}
//           startIcon={<SearchIcon />}
//         >
//           Search
//         </Button>
//       </Box>

//       {data.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
//             <Table>
//               <TableHead sx={{ backgroundColor: '#5865F2', color: '#fff' }}>
//                 <TableRow>
//                   <TableCell sx={{ color: '#fff' }}>Select</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C No.</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C Holder Name</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Member Code</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Balance</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Quarter</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Service Charges</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((row) => (
//                   <TableRow key={row.accountNumber}>
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedItems.includes(row.accountNumber)}
//                         onChange={() => handleCheckboxChange(row.accountNumber)}
//                       />
//                     </TableCell>
//                     <TableCell>{row.accountNumber}</TableCell>
//                     <TableCell>{row.accountHolderName}</TableCell>
//                     <TableCell>{row.memberCode}</TableCell>
//                     <TableCell>{row.balance}</TableCell>
//                     <TableCell>{row.quarter}</TableCell>
//                     <TableCell>{row.smsCharges}</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               sx={{ 
//                 backgroundColor: '#D0B24F', 
//                 color: '#fff',
//                 '&:hover': { backgroundColor: '#B89B3E' },
//                 borderRadius: '12px'
//               }}
//               onClick={handleRetry}
//             >
//               Deduct Charges
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default SMSSearchSaving;









// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';

// const SMSSearchSaving = () => {
//   const [date, setDate] = useState('');
//   const [data, setData] = useState([]);
//   const [selectedItems, setSelectedItems] = useState([]);

//   const token = localStorage.getItem('token');

//   const handleSearch = async () => {
//     try {
//       const response = await axios.get(`https://api.vlnidhi.com/api/admin/saving-accounts/failed-sms-deductions/${date}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

 
//   const handleRetry = async () => {
//     // Extract only the accountNumber values from selectedItems
//     const accountNumbers = selectedItems.map(item => item.accountNumber);
  
//     try {
//       await axios.post('https://api.vlnidhi.com/api/admin/saving-accounts/retry-sms', 
//         accountNumbers, // Send only the accountNumbers array
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log('Retry successful');
//     } catch (error) {
//       console.error('Error retrying charges:', error);
//     }
//   };
//   const handleCheckboxChange = (item) => {
//     setSelectedItems(prev => 
//       prev.includes(item) 
//         ? prev.filter(i => i !== item) 
//         : [...prev, item]
//     );
//   };

//   return (
//     <Box sx={{ 
//       width: '100%', 
//       padding: '20px', 
//       borderRadius: '20px', 
//       backgroundColor: '#fff', 
//       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       maxWidth: '1200px',
//       margin: 'auto',
//       mt: 4,
//     }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Search Box
//       </Typography>
      
//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
//           <Typography sx={{ mb: 1 }}>As On Date:</Typography>
//           <TextField
//             variant="outlined"
//             type="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//             sx={{ 
//               width: '200px',
//               height: '40px', 
//               '& input': { padding: '8px' } 
//             }}
//           />
//         </Box>

//         <Button 
//           variant="contained" 
//           sx={{ 
//             height: '40px', 
//             backgroundColor: '#D0B24F', 
//             color: '#fff',
//             '&:hover': { backgroundColor: '#B89B3E' },
//             alignSelf: 'flex-end'
//           }}
//           onClick={handleSearch}
//           startIcon={<SearchIcon />}
//         >
//           Search
//         </Button>
//       </Box>

//       {data.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <TableContainer component={Paper} sx={{ borderRadius: '12px' }}>
//             <Table>
//               <TableHead sx={{ backgroundColor: '#5865F2', color: '#fff' }}>
//                 <TableRow>
//                   <TableCell sx={{ color: '#fff' }}>Select</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C No.</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>A/C Holder Name</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Member Code</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Balance</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Quarter</TableCell>
//                   <TableCell sx={{ color: '#fff' }}>Service Charges</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {data.map((row) => (
//                   <TableRow key={row.accountNumber}>
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedItems.includes(row.accountNumber)}
//                         onChange={() => handleCheckboxChange(row.accountNumber)}
//                       />
//                     </TableCell>
//                     <TableCell>{row.accountNumber}</TableCell>
//                     <TableCell>{row.accountHolderName}</TableCell>
//                     <TableCell>{row.memberCode}</TableCell>
//                     <TableCell>{row.balance}</TableCell>
//                     <TableCell>{row.quarter}</TableCell>
//                     <TableCell>{row.smsCharges}</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               sx={{ 
//                 backgroundColor: '#D0B24F', 
//                 color: '#fff',
//                 '&:hover': { backgroundColor: '#B89B3E' },
//                 borderRadius: '12px'
//               }}
//               onClick={handleRetry}
//             >
//               Deduct Charges
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default SMSSearchSaving;





// import React, { useState } from 'react';
// import { Box, Button, TextField, InputAdornment, Typography } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';

// const SMSSearchSaving = () => {
//   const [date, setDate] = useState('');
//   const [data, setData] = useState([]);
//   const [selectedItems, setSelectedItems] = useState([]);

//   // Get the token from localStorage
//   const token = localStorage.getItem('token');

//   const handleSearch = async () => {
//     try {
//       const response = await axios.get(`https://api.vlnidhi.com/api/admin/saving-accounts/failed-sms-deductions/${date}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const handleRetry = async () => {
//     try {
//       await axios.post('https://api.vlnidhi.com/api/admin/saving-accounts/retry-sms-charges', 
//         { selectedItems },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       // Handle successful retry
//       console.log('Retry successful');
//     } catch (error) {
//       console.error('Error retrying charges:', error);
//     }
//   };

//   const handleCheckboxChange = (item) => {
//     setSelectedItems(prev => 
//       prev.includes(item) 
//         ? prev.filter(i => i !== item) 
//         : [...prev, item]
//     );
//   };

//   return (
//     <Box sx={{ 
//       width: '100%', 
//       padding: '20px', 
//       borderRadius: '20px', 
//       backgroundColor: '#fff', 
//       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       maxWidth: '1200px',
//       margin: 'auto',
//       mt: 4,
//     }}>
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Search Box
//       </Typography>
      
//       <Box sx={{ display: 'flex', alignItems: 'center' }}>
//         <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
//           <Typography sx={{ mb: 1 }}>As On Date:</Typography>
//           <TextField
//             variant="outlined"
//             type="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//             sx={{ 
//               width: '200px',
//               height: '40px', 
//               '& input': { padding: '8px' } 
//             }}
//           />
//         </Box>

//         <Button 
//           variant="contained" 
//           sx={{ 
//             height: '40px', 
//             backgroundColor: '#D0B24F', 
//             color: '#fff',
//             '&:hover': { backgroundColor: '#B89B3E' },
//             alignSelf: 'flex-end'
//           }}
//           onClick={handleSearch}
//           startIcon={<SearchIcon />}
//         >
//           Search
//         </Button>
//       </Box>

//       {data.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Results
//           </Typography>
//           {data.map(item => (
//             <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//               <input 
//                 type="checkbox" 
//                 checked={selectedItems.includes(item.id)} 
//                 onChange={() => handleCheckboxChange(item.id)} 
//               />
//               <Typography sx={{ ml: 1 }}>{item.description}</Typography>
//             </Box>
//           ))}
//           <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//             <Button
//               variant="contained"
//               sx={{ 
//                 backgroundColor: '#D0B24F', 
//                 color: '#fff',
//                 '&:hover': { backgroundColor: '#B89B3E' },
//                 borderRadius: '12px'
//               }}
//               onClick={handleRetry}
//             >
//               Deduct Charges
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default SMSSearchSaving;
