import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import {
  Container,
  Paper,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Switch,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/system";
import SavingPlanMaster from "./SavingPlanMaster";
import PaymentDetails from "../../Components/GenericComponent/PaymentDetails";

const AddSbAccount = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [formData, setFormData] = useState({
    openingDate: "",
    nomineeName: '',
    nomineeAge: '',
    nomineeRelation: '',
    modeOfOperation: 'Single',
    branchCode: '',
    selectPlan: '',
    openingAmount: '',
    accountStatus: true,
    smsSend: true,
    debitCardIssue: true, 
    name: '',
    memberCode: '',
  });

  const [paymentBy, setPaymentBy] = React.useState('');
  const [accountStatus, setAccountStatus] = React.useState(false);
  const [smsSend, setSmsSend] = React.useState(false);
  const [debitCardIssue, setDebitCardIssue] = React.useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          "https://api.vlnidhi.com/api/admin/getmembers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setMembers(data);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePaymentByChange = (event) => {
    setPaymentBy(event.target.value);
  };

  const handleToggleChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  const handleMemberSelect = (event) => {
    const selectedId = event.target.value;
    const selected = members.find((member) => member.id === selectedId);
    
    setSelectedMember(selected);
  
    setFormData((prevData) => ({
      ...prevData,
      memberCode: selected.memberCode,  // Set the memberCode here
      openingDate: selected.registrationDate || "",
      memberName: `${selected.memberNameTitle} ${selected.memberName}` || "",
      dob: selected.dob || "",
      age: selected.age || "",
      mobileNo: selected.mobileNo || "",
      address: selected.address || "",
      district: selected.district || "",
      state: selected.state || "",
      pinCode: selected.pincode || "",
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await fetch("https://api.vlnidhi.com/api/admin/saving-accounts/create-account", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        
        // Show SweetAlert with success message and accountNumber
        Swal.fire({
          icon: 'success',
          title: 'Saving Account Created Successfully',
          text: `Account Number: ${result.accountNumber}`,
        });
      } else {
        console.error("Failed to create account:", response.statusText);
        // Optionally handle failure here
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally handle the error here
    }
  };

  
  const ImageUploadWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #E0E0E0",
    borderRadius: "8px",
    width: "200px",
    height: "200px",
    backgroundColor: "#F9F9F9",
    alignSelf: "flex-start",
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: "#E0E0E0",
    alignSelf: "flex-start",
  }));

  const Caption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontSize: "12px",
    color: "#BDBDBD",
    alignSelf: "flex-start",
  }));

  return (
    <Box >
      <Typography
        sx={{
          marginTop: { xs: "5%", md: "2%" },
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: 600,
          lineHeight: "36px",
          textAlign: "start",
        }}
      >
        Add SB Account
      </Typography>
      <Paper sx={{ padding: "2%", marginTop: "2%" }}>
        <Typography
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: 500,
            marginBottom: "2%",
          }}
        >
          Saving Plan Master
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Opening Date*
            </Typography>
            <TextField
              fullWidth
              disabled
              type="date"
              value={formData.openingDate}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
  <Typography
    sx={{
      width: "100%",
      fontSize: { xs: "16px", md: "18px" },
      marginBottom: "8px",
    }}
  >
    Select By Member*
  </Typography>
  <FormControl fullWidth>
    <Select
      value={selectedMember ? selectedMember.id : ""}
      name="memberCode"
      onChange={handleMemberSelect}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        "& .MuiInputBase-root": {
          height: { xs: "40px", md: "48px" },
        },
      }}
    >
      {members.map((member) => (
        <MenuItem key={member.id} value={member.id}>
        {member.memberName} - {" "}{member.memberCode}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Member Name*
            </Typography>
            <TextField fullWidth disabled value={formData.memberName} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              DOB*
            </Typography>
            <TextField
              type="date"
              fullWidth
              disabled
              value={formData.dob}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Age
            </Typography>
            <TextField fullWidth disabled value={formData.age} />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Mobile No*
            </Typography>
            <TextField
              fullWidth
              disabled
              value={formData.mobileNo}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Nominee Name
            </Typography>
            <TextField
              fullWidth
              name="nomineeName"
              value={formData.nomineeName}
              onChange={handleInputChange}
              // type="date"
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Nominee Age*
            </Typography>
            <TextField
              fullWidth
              name="nomineeAge"
              value={formData.nomineeAge}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
        <Typography 
          sx={{ 
            width: '100%', 
            fontSize: { xs: '16px', md: '18px' }, 
            marginBottom: '8px' 
          }}
        >
          Nominee Relation*
        </Typography>
        <FormControl 
        
        fullWidth
      
                 name="nomineeRelation"
                value={formData.nomineeRelation}
                onChange={handleInputChange}
        >
          <Select
            labelId="nomineeRelation"
           
            defaultValue=""
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
              '& .MuiInputBase-root': {
                height: { xs: '40px', md: '48px' },
              },
            }}
          
          >
                    <MenuItem value="FATHER">FATHER</MenuItem>
                      <MenuItem value="MOTHER">MOTHER</MenuItem>
                      <MenuItem value="BROTHER">BROTHER</MenuItem>
                      <MenuItem value="SISTER">SISTER</MenuItem>
                      <MenuItem value="HUSBAND">HUSBAND</MenuItem>
                      <MenuItem value="WIFE">WIFE</MenuItem>
                      <MenuItem value="SON">SON</MenuItem>
                      <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
                      <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
                      <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
                      <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
                      <MenuItem value="GRANDSON">GRANDSON</MenuItem>
                      <MenuItem value="OTHER">OTHER</MenuItem>
          </Select>
        </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
  <Typography 
    sx={{ 
      width: '100%', 
      fontSize: { xs: '16px', md: '18px' }, 
      marginBottom: '8px' 
    }}
  >
    Nominee Relation*
  </Typography>
  <FormControl fullWidth>
    <Select
      labelId="nomineeRelation"
      name="nomineeRelation"  // Ensure the name matches your state field
      value={formData.nomineeRelation}  // Bind the value to the state
      onChange={handleInputChange}  // Handle the change to update the state
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        '& .MuiInputBase-root': {
          height: { xs: '40px', md: '48px' },
        },
      }}
    >
      <MenuItem value="FATHER">FATHER</MenuItem>
      <MenuItem value="MOTHER">MOTHER</MenuItem>
      <MenuItem value="BROTHER">BROTHER</MenuItem>
      <MenuItem value="SISTER">SISTER</MenuItem>
      <MenuItem value="HUSBAND">HUSBAND</MenuItem>
      <MenuItem value="WIFE">WIFE</MenuItem>
      <MenuItem value="SON">SON</MenuItem>
      <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
      <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
      <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
      <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
      <MenuItem value="GRANDSON">GRANDSON</MenuItem>
      <MenuItem value="OTHER">OTHER</MenuItem>
    </Select>
  </FormControl>
</Grid>

        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Address*
            </Typography>
            <TextField
              fullWidth
              disabled
              value={formData.address}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              District*
            </Typography>
            <TextField
              fullWidth
              disabled
              value={formData.district}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Branch Name*
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sms-interval-label">Select </InputLabel>
              <Select
                labelId="sms-interval-label"
             
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  "& .MuiInputBase-root": {
                    height: { xs: "40px", md: "48px" },
                  },
                }}
              >
<MenuItem value="MAIN OFFICE">MAIN OFFICE</MenuItem>
<MenuItem value="BANER OFFICE">BANER OFFICE</MenuItem>
<MenuItem value="BHAVDHAN OFFICE">BHAVDHAN OFFICE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              State*
            </Typography>
            <TextField
              fullWidth
              disabled
            value={formData.state}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Pin Code*
            </Typography>
            <TextField
              fullWidth
              disabled
            value={formData.pinCode}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
         <Grid item xs={12} md={6}>
        <Typography 
          sx={{ 
            width: '100%', 
            fontSize: { xs: '16px', md: '18px' }, 
            marginBottom: '8px' 
          }}
        >
          Mode Of Operation*
        </Typography>
        <FormControl 
        fullWidth
        name="modeOfOperation"
              value={formData.modeOfOperation}
              onChange={handleInputChange}
        >
          <Select
            labelId="modeOfOperation"
            defaultValue=""
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
              '& .MuiInputBase-root': {
                height: { xs: '40px', md: '48px' },
              },
            }}
          
          >
                    <MenuItem value="Single">Single</MenuItem>
                      {/* <MenuItem value="Single">Joint</MenuItem> */}

          </Select>
        </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Select Plan*
            </Typography>
            <TextField
              fullWidth
              name="selectPlan"
              // value={formData.'Select Plan'}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Opening Amount*
            </Typography>
            <TextField
              fullWidth
              name="openingAmount"
              value={formData.openingAmount}
              onChange={handleInputChange}
             //value={formData."openingAmount"}
              //onChange={handleInputChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Advisor*
            </Typography>
            <TextField
              fullWidth
              name="name" 
              value={formData.name}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Opening Fees(If any)
            </Typography>
            <TextField
              fullWidth
              name="openingFees"
              value={formData.openingfees}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Branch Code
            </Typography>
            <TextField
              fullWidth
              name="branchCode"
              value={formData.branchCode}
              onChange={handleInputChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                "& .MuiInputBase-root": {
                  height: { xs: "40px", md: "48px" },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="center"
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Applicant Photo
            </Typography>
            <ImageUploadWrapper>
              <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            container
            direction="column"
            alignItems="c"
          >
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                marginBottom: "8px",
              }}
            >
              Signature
            </Typography>
            <ImageUploadWrapper>
              {/* Removed PersonIcon for Signature */}
            </ImageUploadWrapper>
            <UploadButton variant="contained" component="label">
              Choose File
              <input type="file" hidden />
            </UploadButton>
            <Caption>Photo Size: Maximum 10MB</Caption>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ marginTop: "2%" }}>
      <Box
      sx={{
        width: '100%',
        maxWidth: '95%', // Maximum width for large screens
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        borderRadius: '100px',        
        margin: 'auto',
      }}
    >
      <h2>Payment Details</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="payment-by-label">Payment By*</InputLabel>
            <Select
              labelId="payment-by-label"
              value={paymentBy}
              onChange={handlePaymentByChange}
              label="Payment By*"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Cash">Cash</MenuItem>
              <MenuItem value="Credit Card">Credit Card</MenuItem>
              <MenuItem value="Debit Card">Debit Card</MenuItem>
              <MenuItem value="Net Banking">Net Banking</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Remarks" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center">
            <Switch
              checked={accountStatus}
              onChange={handleToggleChange(setAccountStatus)}
            />
            <span>Account Status*</span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center">
            <Switch
              checked={smsSend}
              onChange={handleToggleChange(setSmsSend)}
            />
            <span>SMS Send*</span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" alignItems="center">
            <Switch
              checked={debitCardIssue}
              onChange={handleToggleChange(setDebitCardIssue)}
            />
            <span>Debit Card Issue*</span>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: { xs: 'center', md: 'center' }, marginTop: '20px',justifyContent:'flex-end'}}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFD700',
              color: '#000',
              marginRight: { xs: '0', md: '10px' },
              width: { xs: '100%', sm: 'auto' },
              marginBottom: { xs: '10px', sm: '0' },
            }}
          >
            New
          </Button>
          <Button
            variant="contained"
          //  type="submit"
          onClick={handleFormSubmit}
            sx={{
              backgroundColor: '#3F51B5',
              color: '#fff',
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
      </Paper>
    </Box>
  );
};

export default AddSbAccount;











// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2"; // Import SweetAlert2
// import {
//   Container,
//   Paper,
//   Grid,
//   Typography,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Select,
//   TextField,
//   Button,
//   Table,
//   Box,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
//   Switch,
// } from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import { styled } from "@mui/system";
// import SavingPlanMaster from "./SavingPlanMaster";
// import PaymentDetails from "../../Components/GenericComponent/PaymentDetails";

// const AddSbAccount = () => {
//   const [members, setMembers] = useState([]);
//   const [selectedMember, setSelectedMember] = useState(null);
//   const [formData, setFormData] = useState({
//     openingDate: "",
//     nomineeName: '',
//     nomineeAge: '',
//     nomineeRelation: '',
//     modeOfOperation: 'Single',
//     branchCode: '',
//     selectPlan: '',
//     openingAmount: '',
//     accountStatus: true,
//     smsSend: true,
//     debitCardIssue: true, 
//     name: '',
//     memberCode: '',
//   });

//   const [paymentBy, setPaymentBy] = React.useState('');
//   const [accountStatus, setAccountStatus] = React.useState(false);
//   const [smsSend, setSmsSend] = React.useState(false);
//   const [debitCardIssue, setDebitCardIssue] = React.useState(false);

//   useEffect(() => {
//     const fetchMembers = async () => {
//       const token = localStorage.getItem("token");
//       try {
//         const response = await fetch(
//           "https://api.vlnidhi.com/api/admin/getmembers",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const data = await response.json();
//         setMembers(data);
//       } catch (error) {
//         console.error("Error fetching members:", error);
//       }
//     };

//     fetchMembers();
//   }, []);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handlePaymentByChange = (event) => {
//     setPaymentBy(event.target.value);
//   };

//   const handleToggleChange = (setter) => (event) => {
//     setter(event.target.checked);
//   };

//   const handleMemberSelect = (event) => {
//     const selectedId = event.target.value;
//     const selected = members.find((member) => member.id === selectedId);
    
//     setSelectedMember(selected);
  
//     setFormData((prevData) => ({
//       ...prevData,
//       memberCode: selected.memberCode,  // Set the memberCode here
//       openingDate: selected.registrationDate || "",
//       memberName: `${selected.memberNameTitle} ${selected.memberName}` || "",
//       dob: selected.dob || "",
//       age: selected.age || "",
//       mobileNo: selected.mobileNo || "",
//       address: selected.address || "",
//       district: selected.district || "",
//       state: selected.state || "",
//       pinCode: selected.pincode || "",
//     }));
//   };

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();
//     const token = localStorage.getItem("token");

//     try {
//       const response = await fetch("https://api.vlnidhi.com/api/admin/saving-accounts/create-account", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         const result = await response.json();
//         console.log("Success:", result);
        
//         // Show SweetAlert with success message and accountNumber
//         Swal.fire({
//           icon: 'success',
//           title: 'Saving Account Created Successfully',
//           text: `Account Number: ${result.accountNumber}`,
//         });
//       } else {
//         console.error("Failed to create account:", response.statusText);
//         // Optionally handle failure here
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       // Optionally handle the error here
//     }
//   };

  
//   const ImageUploadWrapper = styled(Box)(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     border: "2px solid #E0E0E0",
//     borderRadius: "8px",
//     width: "200px",
//     height: "200px",
//     backgroundColor: "#F9F9F9",
//     alignSelf: "flex-start",
//   }));

//   const UploadButton = styled(Button)(({ theme }) => ({
//     textTransform: "none",
//     marginTop: theme.spacing(2),
//     backgroundColor: "#E0E0E0",
//     alignSelf: "flex-start",
//   }));

//   const Caption = styled(Typography)(({ theme }) => ({
//     marginTop: theme.spacing(1),
//     fontSize: "12px",
//     color: "#BDBDBD",
//     alignSelf: "flex-start",
//   }));

//   return (
//     <Box >
//       <Typography
//         sx={{
//           marginTop: { xs: "5%", md: "2%" },
//           fontSize: { xs: "20px", md: "24px" },
//           fontWeight: 600,
//           lineHeight: "36px",
//           textAlign: "start",
//         }}
//       >
//         Add SB Account
//       </Typography>
//       <Paper sx={{ padding: "2%", marginTop: "2%" }}>
//         <Typography
//           sx={{
//             fontSize: { xs: "18px", md: "20px" },
//             fontWeight: 500,
//             marginBottom: "2%",
//           }}
//         >
//           Saving Plan Master
//         </Typography>

//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Opening Date*
//             </Typography>
//             <TextField
//               fullWidth
//               disabled
//               type="date"
//               value={formData.openingDate}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//   <Typography
//     sx={{
//       width: "100%",
//       fontSize: { xs: "16px", md: "18px" },
//       marginBottom: "8px",
//     }}
//   >
//     Select By Member*
//   </Typography>
//   <FormControl fullWidth>
//     <Select
//       value={selectedMember ? selectedMember.id : ""}
//       name="memberCode"
//       onChange={handleMemberSelect}
//       sx={{
//         backgroundColor: "#FFFFFF",
//         borderRadius: "10px",
//         "& .MuiInputBase-root": {
//           height: { xs: "40px", md: "48px" },
//         },
//       }}
//     >
//       {members.map((member) => (
//         <MenuItem key={member.id} value={member.id}>
//           {member.memberCode}
//         </MenuItem>
//       ))}
//     </Select>
//   </FormControl>
// </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} sm={6} md={4}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Member Name*
//             </Typography>
//             <TextField fullWidth disabled value={formData.memberName} />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               DOB*
//             </Typography>
//             <TextField
//               type="date"
//               fullWidth
//               disabled
//               value={formData.dob}
//               InputLabelProps={{ shrink: true }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={2}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Age
//             </Typography>
//             <TextField fullWidth disabled value={formData.age} />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Mobile No*
//             </Typography>
//             <TextField
//               fullWidth
//               disabled
//               value={formData.mobileNo}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Nominee Name
//             </Typography>
//             <TextField
//               fullWidth
//               name="nomineeName"
//               value={formData.nomineeName}
//               onChange={handleInputChange}
//               // type="date"
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Nominee Age*
//             </Typography>
//             <TextField
//               fullWidth
//               name="nomineeAge"
//               value={formData.nomineeAge}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           {/* <Grid item xs={12} md={6}>
//         <Typography 
//           sx={{ 
//             width: '100%', 
//             fontSize: { xs: '16px', md: '18px' }, 
//             marginBottom: '8px' 
//           }}
//         >
//           Nominee Relation*
//         </Typography>
//         <FormControl 
        
//         fullWidth
      
//                  name="nomineeRelation"
//                 value={formData.nomineeRelation}
//                 onChange={handleInputChange}
//         >
//           <Select
//             labelId="nomineeRelation"
           
//             defaultValue=""
//             sx={{
//               backgroundColor: '#FFFFFF',
//               borderRadius: '10px',
//               '& .MuiInputBase-root': {
//                 height: { xs: '40px', md: '48px' },
//               },
//             }}
          
//           >
//                     <MenuItem value="FATHER">FATHER</MenuItem>
//                       <MenuItem value="MOTHER">MOTHER</MenuItem>
//                       <MenuItem value="BROTHER">BROTHER</MenuItem>
//                       <MenuItem value="SISTER">SISTER</MenuItem>
//                       <MenuItem value="HUSBAND">HUSBAND</MenuItem>
//                       <MenuItem value="WIFE">WIFE</MenuItem>
//                       <MenuItem value="SON">SON</MenuItem>
//                       <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
//                       <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
//                       <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
//                       <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
//                       <MenuItem value="GRANDSON">GRANDSON</MenuItem>
//                       <MenuItem value="OTHER">OTHER</MenuItem>
//           </Select>
//         </FormControl>
//           </Grid> */}
//           <Grid item xs={12} md={6}>
//   <Typography 
//     sx={{ 
//       width: '100%', 
//       fontSize: { xs: '16px', md: '18px' }, 
//       marginBottom: '8px' 
//     }}
//   >
//     Nominee Relation*
//   </Typography>
//   <FormControl fullWidth>
//     <Select
//       labelId="nomineeRelation"
//       name="nomineeRelation"  // Ensure the name matches your state field
//       value={formData.nomineeRelation}  // Bind the value to the state
//       onChange={handleInputChange}  // Handle the change to update the state
//       sx={{
//         backgroundColor: '#FFFFFF',
//         borderRadius: '10px',
//         '& .MuiInputBase-root': {
//           height: { xs: '40px', md: '48px' },
//         },
//       }}
//     >
//       <MenuItem value="FATHER">FATHER</MenuItem>
//       <MenuItem value="MOTHER">MOTHER</MenuItem>
//       <MenuItem value="BROTHER">BROTHER</MenuItem>
//       <MenuItem value="SISTER">SISTER</MenuItem>
//       <MenuItem value="HUSBAND">HUSBAND</MenuItem>
//       <MenuItem value="WIFE">WIFE</MenuItem>
//       <MenuItem value="SON">SON</MenuItem>
//       <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
//       <MenuItem value="DAUGHTER_IN_LAW">DAUGHTER_IN_LAW</MenuItem>
//       <MenuItem value="BROTHER_IN_LAW">BROTHER_IN_LAW</MenuItem>
//       <MenuItem value="GRAND_DAUGHTER">GRAND_DAUGHTER</MenuItem>
//       <MenuItem value="GRANDSON">GRANDSON</MenuItem>
//       <MenuItem value="OTHER">OTHER</MenuItem>
//     </Select>
//   </FormControl>
// </Grid>

//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Address*
//             </Typography>
//             <TextField
//               fullWidth
//               disabled
//               value={formData.address}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               District*
//             </Typography>
//             <TextField
//               fullWidth
//               disabled
//               value={formData.district}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Branch Name*
//             </Typography>
//             <FormControl fullWidth>
//               <InputLabel id="sms-interval-label">Select </InputLabel>
//               <Select
//                 labelId="sms-interval-label"
//                 defaultValue=""
//                 sx={{
//                   backgroundColor: "#FFFFFF",
//                   borderRadius: "10px",
//                   "& .MuiInputBase-root": {
//                     height: { xs: "40px", md: "48px" },
//                   },
//                 }}
//               >
//                 <MenuItem>Select</MenuItem>
//                 <MenuItem>Select</MenuItem>
//                 <MenuItem>Select</MenuItem>
//                 <MenuItem>Select</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               State*
//             </Typography>
//             <TextField
//               fullWidth
//               disabled
//             value={formData.state}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Pin Code*
//             </Typography>
//             <TextField
//               fullWidth
//               disabled
//             value={formData.pinCode}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//          <Grid item xs={12} md={6}>
//         <Typography 
//           sx={{ 
//             width: '100%', 
//             fontSize: { xs: '16px', md: '18px' }, 
//             marginBottom: '8px' 
//           }}
//         >
//           Mode Of Operation*
//         </Typography>
//         <FormControl 
//         fullWidth
//         name="modeOfOperation"
//               value={formData.modeOfOperation}
//               onChange={handleInputChange}
//         >
//           <Select
//             labelId="modeOfOperation"
//             defaultValue=""
//             sx={{
//               backgroundColor: '#FFFFFF',
//               borderRadius: '10px',
//               '& .MuiInputBase-root': {
//                 height: { xs: '40px', md: '48px' },
//               },
//             }}
          
//           >
//                     <MenuItem value="Single">Single</MenuItem>
//                       {/* <MenuItem value="Single">Joint</MenuItem> */}

//           </Select>
//         </FormControl>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Select Plan*
//             </Typography>
//             <TextField
//               fullWidth
//               name="selectPlan"
//               // value={formData.'Select Plan'}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Opening Amount*
//             </Typography>
//             <TextField
//               fullWidth
//               name="openingAmount"
//               value={formData.openingAmount}
//               onChange={handleInputChange}
//              //value={formData."openingAmount"}
//               //onChange={handleInputChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Advisor*
//             </Typography>
//             <TextField
//               fullWidth
//               name="name" 
//               value={formData.name}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Opening Fees(If any)
//             </Typography>
//             <TextField
//               fullWidth
//               name="openingFees"
//               value={formData.openingfees}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Branch Code
//             </Typography>
//             <TextField
//               fullWidth
//               name="branchCode"
//               value={formData.branchCode}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: "#FFFFFF",
//                 borderRadius: "10px",
//                 "& .MuiInputBase-root": {
//                   height: { xs: "40px", md: "48px" },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={3}
//             container
//             direction="column"
//             alignItems="center"
//           >
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Applicant Photo
//             </Typography>
//             <ImageUploadWrapper>
//               <PersonIcon style={{ fontSize: 100, color: "#BDBDBD" }} />
//             </ImageUploadWrapper>
//             <UploadButton variant="contained" component="label">
//               Choose File
//               <input type="file" hidden />
//             </UploadButton>
//             <Caption>Photo Size: Maximum 10MB</Caption>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={3}
//             container
//             direction="column"
//             alignItems="c"
//           >
//             <Typography
//               sx={{
//                 width: "100%",
//                 fontSize: { xs: "16px", md: "18px" },
//                 marginBottom: "8px",
//               }}
//             >
//               Signature
//             </Typography>
//             <ImageUploadWrapper>
//               {/* Removed PersonIcon for Signature */}
//             </ImageUploadWrapper>
//             <UploadButton variant="contained" component="label">
//               Choose File
//               <input type="file" hidden />
//             </UploadButton>
//             <Caption>Photo Size: Maximum 10MB</Caption>
//           </Grid>
//         </Grid>
//       </Paper>
//       <Paper sx={{ marginTop: "2%" }}>
//       <Box
//       sx={{
//         width: '100%',
//         maxWidth: '95%', // Maximum width for large screens
//         padding: '20px',
//         backgroundColor: '#fff',
//         borderRadius: '15px',
//         borderRadius: '100px',        
//         margin: 'auto',
//       }}
//     >
//       <h2>Payment Details</h2>
//       <Grid container spacing={2}>
//         <Grid item xs={12} md={6}>
//           <FormControl fullWidth>
//             <InputLabel id="payment-by-label">Payment By*</InputLabel>
//             <Select
//               labelId="payment-by-label"
//               value={paymentBy}
//               onChange={handlePaymentByChange}
//               label="Payment By*"
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               <MenuItem value="Cash">Cash</MenuItem>
//               <MenuItem value="Credit Card">Credit Card</MenuItem>
//               <MenuItem value="Debit Card">Debit Card</MenuItem>
//               <MenuItem value="Net Banking">Net Banking</MenuItem>
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <TextField fullWidth label="Remarks" variant="outlined" />
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Box display="flex" alignItems="center">
//             <Switch
//               checked={accountStatus}
//               onChange={handleToggleChange(setAccountStatus)}
//             />
//             <span>Account Status*</span>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Box display="flex" alignItems="center">
//             <Switch
//               checked={smsSend}
//               onChange={handleToggleChange(setSmsSend)}
//             />
//             <span>SMS Send*</span>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Box display="flex" alignItems="center">
//             <Switch
//               checked={debitCardIssue}
//               onChange={handleToggleChange(setDebitCardIssue)}
//             />
//             <span>Debit Card Issue*</span>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sx={{ textAlign: { xs: 'center', md: 'center' }, marginTop: '20px',justifyContent:'flex-end'}}
//         >
//           <Button
//             variant="contained"
//             sx={{
//               backgroundColor: '#FFD700',
//               color: '#000',
//               marginRight: { xs: '0', md: '10px' },
//               width: { xs: '100%', sm: 'auto' },
//               marginBottom: { xs: '10px', sm: '0' },
//             }}
//           >
//             New
//           </Button>
//           <Button
//             variant="contained"
//           //  type="submit"
//           onClick={handleFormSubmit}
//             sx={{
//               backgroundColor: '#3F51B5',
//               color: '#fff',
//               width: { xs: '100%', sm: 'auto' },
//             }}
//           >
//             Save
//           </Button>
//         </Grid>
//       </Grid>
//     </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default AddSbAccount;
