import React from "react";
import SignIn from "./Components/Signin";
import Sidebar from "./Components/Sidebar";
import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "./Pages/PageNotFound";
import ProtectedRoute from "./Components/ProtectedRoute";
import AddMoney from "./Componentstest/AddMoney";
import PayoutScreen from "./Componentstest/Payout";
const App = () => {
  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route index element={<SignIn />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/addmoneyNew" element={<AddMoney />} />
        <Route path="/payoutnew" element={<PayoutScreen />} />
        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Sidebar />
            </ProtectedRoute>
          }
        />
        {/* Catch-All Route */}
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};
export default App;







// import React from "react";
// import SignIn from "./Components/Signin";
// import Sidebar from "./Components/Sidebar";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { PageNotFound } from "./Pages/PageNotFound";
// import ProtectedRoute from "./Components/ProtectedRoute";
// import AddMoney from "./Componentstest/AddMoney";
// import PayoutScreen from "./Componentstest/Payout";
// // import  AddMoney from "./test/AddMoney";


// const App = () => {
//   return (
//     <div className="App">
//       {/* <BrowserRouter> */}
//         <Routes>
//           <Route index element={<SignIn />} />
//           <Route
//             path="/dashboard"
//             element={<ProtectedRoute element={<Sidebar />} />}
//           />
//           {/* <Route path="/dashboard" element={<Sidebar />} /> */}
//           <Route path="/login" element={<SignIn />} />

//           <Route path="/*" element={<PageNotFound />} />
//           <Route path="/addmoneyNew" element={<AddMoney/>} />
//           <Route path="/payoutnew" element={<PayoutScreen/>} />
//         </Routes>
//       {/* </BrowserRouter> */}
//     </div>
//   );
// };

// export default App;








// import React from "react";
// import SignIn from "./Components/Signin";
// import Sidebar from "./Components/Sidebar";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { PageNotFound } from "./Pages/PageNotFound";
// import ProtectedRoute from "./Components/ProtectedRoute";
// // import  AddMoney from "./test/AddMoney";


// const App = () => {
//   return (
//     <div className="App">
//       {/* <BrowserRouter> */}
//         <Routes>
//           <Route index element={<SignIn />} />
//           <Route
//             path="/dashboard"
//             element={<ProtectedRoute element={<Sidebar />} />}
//           />
//           {/* <Route path="/dashboard" element={<Sidebar />} /> */}
//           <Route path="/login" element={<SignIn />} />

//           <Route path="/*" element={<PageNotFound />} />
//           {/* <Route path="/addmoney" element={<AddMoney />} /> */}
//         </Routes>
//       {/* </BrowserRouter> */}
//     </div>
//   );
// };

// export default App;
