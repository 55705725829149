// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Box,
//   TextField,
//   Button,
//   FormControl,
//   Select,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Checkbox,
//   Typography,
//   InputAdornment,
// } from "@mui/material";
// import axios from "axios";
// import { toast } from "react-toastify";

// const SavingTransactionApproval = () => {
//   const [branch, setBranch] = useState("");
//   const [closingBranches, setClosingBranches] = useState([]);
//   const [transactions, setTransactions] = useState([]);
//   const [selectedIds, setSelectedIds] = useState(new Set()); // State to track selected transaction IDs
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [showTable, setShowTable] = useState(false);

//   const [formData, setFormData] = useState({
//     closingBranch: "",
//     fromDate: "",
//     toDate: "",
//   });

//   useEffect(() => {
//     const token = localStorage.getItem("token");

//     // Fetch closing branches
//     axios
//       .get("https://api.vlnidhi.com/api/admin/getallbid", {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => setClosingBranches(response.data))
//       .catch((error) => console.error("Error fetching closing branches:", error));
//   }, []);

//   const handleSearch = () => {
//     const token = localStorage.getItem("token");
//     const { closingBranch, fromDate, toDate } = formData;

//     axios
//       .get("https://api.vlnidhi.com/api/admin/transactions/getpendingtransaction", {
//         headers: { Authorization: `Bearer ${token}` },
//         params: {
//           branch: closingBranch,
//           fromDate,
//           toDate,
//         },
//       })
//       .then((response) => {
//         setTransactions(response.data);
//         setShowTable(true);
//       })
//       .catch((error) => console.error("Error fetching transactions:", error));
//   };

//   const handleCheckboxChange = (id) => {
//     setSelectedIds((prev) => {
//       const newSelectedIds = new Set(prev);
//       if (newSelectedIds.has(id)) {
//         newSelectedIds.delete(id);
//       } else {
//         newSelectedIds.add(id);
//       }
//       return newSelectedIds;
//     });
//   };


//   const handleApproval = () => {
//     const token = localStorage.getItem("token");
  
//     // Convert selected IDs to an array and send as JSON
//     const payload = Array.from(selectedIds);
  
//     axios.put(
//       `https://api.vlnidhi.com/api/admin/transactions/transactinapproval`,
//       payload, // Send array of IDs
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     )
//     .then(() => {
//       toast.success("Transactions approved successfully!");
//       handleSearch(); // Optionally refresh transactions after approval
//     })
//     .catch((error) => {
//       console.error("Error approving transactions:", error);
//       toast.error("Error approving transactions.");
//     });
//   };
  
//   const handleRejection = () => {
//     const token = localStorage.getItem("token");
  
//     // Convert selected IDs to an array and send as JSON
//     const payload = Array.from(selectedIds);
  
//     axios.put(
//       `https://api.vlnidhi.com/api/admin/transactions/rejecttransaction`,
//       payload, // Send array of IDs
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       }
//     )
//     .then(() => {
//       toast.success("Transactions rejected successfully!");
//       handleSearch(); // Optionally refresh transactions after rejection
//     })
//     .catch((error) => {
//       console.error("Error rejecting transactions:", error);
//       toast.error("Error rejecting transactions.");
//     });
//   };

//   const handleFormChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Swal from 'sweetalert2'; // Import SweetAlert2

const SavingTransactionApproval = () => {
  const [branch, setBranch] = useState("");
  const [closingBranches, setClosingBranches] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedIds, setSelectedIds] = useState(new Set()); // State to track selected transaction IDs
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showTable, setShowTable] = useState(false);

  const [formData, setFormData] = useState({
    closingBranch: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch closing branches
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setClosingBranches(response.data))
      .catch((error) => console.error("Error fetching closing branches:", error));
  }, []);

  const handleSearch = () => {
    const token = localStorage.getItem("token");
    const { closingBranch, fromDate, toDate } = formData;

    axios
      .get("https://api.vlnidhi.com/api/admin/transactions/getpendingtransaction", {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          branch: closingBranch,
          fromDate,
          toDate,
        },
      })
      .then((response) => {
        setTransactions(response.data);
        setShowTable(true);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An error occurred while fetching transactions.',
        });
      });
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) => {
      const newSelectedIds = new Set(prev);
      if (newSelectedIds.has(id)) {
        newSelectedIds.delete(id);
      } else {
        newSelectedIds.add(id);
      }
      return newSelectedIds;
    });
  };

  const handleApproval = () => {
    const token = localStorage.getItem("token");
  
    // Convert selected IDs to an array and send as JSON
    const payload = Array.from(selectedIds);
  
    axios.put(
      `https://api.vlnidhi.com/api/admin/transactions/transactinapproval`,
      payload, // Send array of IDs
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Transactions approved successfully!',
      });
      handleSearch(); // Optionally refresh transactions after approval
    })
    .catch((error) => {
      console.error("Error approving transactions:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Error approving transactions.',
      });
    });
  };
  
  const handleRejection = () => {
    const token = localStorage.getItem("token");
  
    // Convert selected IDs to an array and send as JSON
    const payload = Array.from(selectedIds);
  
    axios.put(
      `https://api.vlnidhi.com/api/admin/transactions/rejecttransaction`,
      payload, // Send array of IDs
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Transactions rejected successfully!',
      });
      handleSearch(); // Optionally refresh transactions after rejection
    })
    .catch((error) => {
      console.error("Error rejecting transactions:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Error rejecting transactions.',
      });
    });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ fontSize: "26px", fontWeight: "bold", mb: 2, mt: 2 }}>
        Saving Transaction Approval
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "#f7f8fc",
          p: 4,
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ fontSize: "22px" }}>Search Box</Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl>
            <Select
              name="closingBranch"
              value={formData.closingBranch}
              onChange={handleFormChange}
              variant="outlined"
              sx={{ width: "360px" }}
            >
              {closingBranches.map((branch) => (
                <MenuItem key={branch.id} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="fromDate"
            variant="outlined"
            label="From Date"
            type="date"
            value={formData.fromDate}
            onChange={handleFormChange}
            sx={{ height: "48px", width: "360px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
              inputProps: {
                pattern: "\\d{4}-\\d{2}-\\d{2}",
              },
            }}
          />
          <TextField
            name="toDate"
            variant="outlined"
            label="To Date"
            type="date"
            value={formData.toDate}
            onChange={handleFormChange}
            sx={{ height: "48px", width: "360px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
              inputProps: {
                pattern: "\\d{4}-\\d{2}-\\d{2}",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              height: "48px",
              width: "150px",
              background: "#DFBF41",
              color: "white",
              marginTop: "3px",
              borderRadius: "10px",
            }}
          >
            Search
          </Button>
        </Box>
      </Box>

      {showTable && (
        <Box sx={{ mt: 3, p: 2 }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
            Saving Txn Data List
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{  backgroundColor: "#413AF1" }}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox sx={{ color: "white" }} />
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Sl No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Account No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Applicant Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Txn Date
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Inst No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Txn Type
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Phone No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Member Code
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Branch
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Amount
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Pay Mode
                    </TableCell>
                    {/* <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Actions
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((txn, index) => (
                    <TableRow key={txn.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedIds.has(txn.id)}
                          onChange={() => handleCheckboxChange(txn.id)}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{txn.accountNo}</TableCell>
                      <TableCell>{txn.applicantName}</TableCell>
                      <TableCell>{txn.txnDate ? txn.txnDate : "N/A"}</TableCell>
                      <TableCell>{txn.instNo ? txn.instNo : "N/A"}</TableCell>
                      <TableCell>{txn.txnType}</TableCell>
                      <TableCell>{txn.phoneNo}</TableCell>
                      <TableCell>{txn.memberCode}</TableCell>
                      <TableCell>{txn.branch}</TableCell>
                      <TableCell>{txn.amount}</TableCell>
                      <TableCell>{txn.payMode}</TableCell>
                     {/* <TableCell></TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleApproval}
              disabled={selectedIds.size === 0}
              sx={{ marginRight: 2 }}
            >
              Approve 
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleRejection}
              disabled={selectedIds.size === 0}
            >
              Reject 
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SavingTransactionApproval;



// import React, { useState } from "react";
// import {
//   Container,
//   Box,
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Checkbox,
//   Typography,
//   InputAdornment,
// } from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// const SavingTransactionApproval = () => {
//   const [branch, setBranch] = useState("");
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   const [showTable, setShowTable] = useState(false);

//   const handleSearch = () => {
//     setShowTable(true);
//   };

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography sx={{ fontSize: "26px", fontWeight: "bold", mb: 2, mt: 2 }}>
//         Saving Transaction Approval
//       </Typography>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "16px",
//           backgroundColor: "#f7f8fc",
//           p: 4,
//           borderRadius: "8px",
//         }}
//       >
//         {/* Search Box */}
//         <Typography sx={{ fontSize: "22px" }}>Search Box</Typography>

//         <Box sx={{ display: "flex", gap: 2 }}>
//           {/* Branch Field */}
//           <TextField
//             label="Branch"
//             variant="outlined"
//             select
//             sx={{ width: "360px" }}
//           >
//             <MenuItem value={"Branch1"}>Branch 1</MenuItem>
//             <MenuItem value={"Branch2"}>Branch 2</MenuItem>
//           </TextField>

//           <TextField
//             fullWidth
//             variant="outlined"
//             label="From Date"
//             type="date"
//             sx={{ height: "48px", width: "360px" }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   {/* <CalendarTodayIcon /> */}
//                 </InputAdornment>
//               ),
//               inputProps: {
//                 pattern: "\\d{4}-\\d{2}-\\d{2}",
//               },
//             }}
//           />
//           <TextField
//             fullWidth
//             variant="outlined"
//             label="To Date"
//             type="date"
//             sx={{ height: "48px", width: "360px" }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   {/* <CalendarTodayIcon /> */}
//                 </InputAdornment>
//               ),
//               inputProps: {
//                 pattern: "\\d{4}-\\d{2}-\\d{2}",
//               },
//             }}
//           />
//           <Button
//             variant="contained"
//             onClick={handleSearch}
//             sx={{
//               height: "48px",
//               width: "150px",
//               background: "#DFBF41",
//               color: "white",
//               marginTop: "3px",
//               borderRadius: "10px",
//             }}
//           >
//             Search
//           </Button>
//         </Box>
//       </Box>

//       {/* Table Data */}
//       {showTable && (
//         <Box sx={{ mt: 3, p: 2 }}>
//             <Typography sx={{fontSize: "22px", fontWeight: "bold"}}>Saving Txn Data List</Typography>
//           <Box sx={{ mt: 3 }}>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead sx={{ backgroundColor: "#3c3cc1" }}>
//                   <TableRow>
//                     <TableCell padding="checkbox">
//                       <Checkbox sx={{ color: "white" }} />
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Sl No
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Account No
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Applicant Name
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Txn Date
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Inst No
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Txn Type
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Phone No
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Member Code
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Branch
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Amount
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Pay Mode
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell padding="checkbox">
//                       <Checkbox />
//                     </TableCell>
//                     <TableCell>01</TableCell>
//                     <TableCell>VL001</TableCell>
//                     <TableCell>Pratik Jadhav</TableCell>
//                     <TableCell>12/01/2024</TableCell>
//                     <TableCell>02</TableCell>
//                     <TableCell>Withdraw</TableCell>
//                     <TableCell>9876543210</TableCell>
//                     <TableCell>VL0001</TableCell>
//                     <TableCell>Main Office</TableCell>
//                     <TableCell>20</TableCell>
//                     <TableCell>Transfer</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell padding="checkbox">
//                       <Checkbox />
//                     </TableCell>
//                     <TableCell>02</TableCell>
//                     <TableCell>VL001</TableCell>
//                     <TableCell>Pratik Jadhav</TableCell>
//                     <TableCell>12/01/2024</TableCell>
//                     <TableCell>02</TableCell>
//                     <TableCell>Withdraw</TableCell>
//                     <TableCell>9876543210</TableCell>
//                     <TableCell>VL0001</TableCell>
//                     <TableCell>Main Office</TableCell>
//                     <TableCell>20</TableCell>
//                     <TableCell>Transfer</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <Box
//               sx={{
//                 mt: 2,
//                 display: "flex",
//                 gap: 2,
//                 justifyContent: "flex-end",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#d9534f",
//                   color: "#fff",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Reject
//               </Button>
//               <Button
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#f3c13a",
//                   color: "#fff",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Approve
//               </Button>
//             </Box>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default SavingTransactionApproval;
