
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, CardMedia, Typography, Box, TextField, Button, useMediaQuery } from '@mui/material';
import loginImage from '../Assets/Login/Login_image.svg';
import vllogo from '../Assets/Login/image 1.svg';
import username from '../Assets/Login/Username.svg';
import password from '../Assets/Login/Password.svg';
import logingif from '../Assets/Login/bankinggif.gif'
import '@fontsource/poppins';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser } from '../redux/actions/authActions';

const SignIn = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');
  const auth = useSelector((state) => state.auth);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    dispatch(loginUser(formData));

    if (auth.error) {
      setError('Invalid username or password');
      toast.error('Login Failed: Invalid username or password');
    } 
    if(auth.token) {
      navigate('/dashboard');
      toast.success('Login Successful!');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: '100vh',
        backgroundColor: '#F4F4F4',
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: '12%' }}>
              <img
                src={loginImage}
                alt='Login'
                style={{ width: '100%', height: '90%' }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          boxShadow: isMobile ? 'none' : '-20px 4px 40px 0px #0000002B',
          borderRadius: isMobile ? '0' : '8px',
          width: isMobile ? '100%' : '80px',
          p: 4,
          ml: isMobile ? 0 : 30,
        }}
      >
        <CardMedia
          component='img'
          image={vllogo}
          alt='VL Logo'
          sx={{
            width: '100px',
            height: '100px',
            mb: 6,
            marginTop: isMobile ? '20px' : '60px',
          }}
        />
        <Grid
          item
          sx={{
            height: '74px',
            gap: 0,
            opacity: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '36px',
            }}
          >
            VENKETLAXMI URBAN
            <br />
            PUNE NIDHI LTD
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid
            item
            sx={{
              width: isMobile ? '100%' : '430px',
              height: '52px',
              mt: 4,
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16.16px',
                fontWeight: 400,
                lineHeight: '24.23px',
                textAlign: 'left',
                mb: 1,
              }}
            >
              User ID
            </Typography>
            <Grid
              item
              sx={{
                width: '100%',
                height: '50px',
                borderRadius: '8px',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <TextField
                variant='outlined'
                placeholder='Enter User ID'
                name='username'
                value={formData.username}
                onChange={handleChange}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '8px',
                    },
                    height: '50px',
                    backgroundColor: '#EEEEEE',
                  },
                }}
              />
              <Box
                sx={{
                  width: '50.59px',
                  height: '50px',
                  top: 0,
                  right: 0,
                  borderRadius: '8px',
                  backgroundColor: '#1E2772',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={username}
                  alt='Icon'
                  style={{ width: '50px', height: '50px' }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              width: isMobile ? '100%' : '430px',
              height: '52px',
              mt: 7,
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16.16px',
                fontWeight: 400,
                lineHeight: '24.23px',
                textAlign: 'left',
                mb: 1,
              }}
            >
              Password
            </Typography>
            <Grid
              item
              sx={{
                width: '100%',
                height: '50px',
                borderRadius: '8px',
                backgroundColor: '#EEEEEE',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <TextField
                variant='outlined'
                name='password'
                type='password'
                value={formData.password}
                onChange={handleChange}
                placeholder='Enter Your Password'
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '8px',
                    },
                    height: '50px',
                    backgroundColor: '#EEEEEE',
                  },
                }}
              />
              <Box
                sx={{
                  width: '50.59px',
                  height: '50px',
                  top: 0,
                  right: 0,
                  borderRadius: '8px',
                  backgroundColor: '#1E2772',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={password}
                  alt='Icon'
                  style={{ width: '50px', height: '50px' }}
                />
              </Box>
            </Grid>
          </Grid>
          {error && (
            <Typography
              sx={{
                color: 'red',
                mt: 5,
                mb: -3,
                textAlign: 'center',
              }}
            >
              {error}
            </Typography>
          )}
          <Grid
            item
            sx={{
              width: '100%',
              mt: 4,
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                color: '#1E2772',
                cursor: 'pointer',
              }}
            >
              Forgot password?
            </Typography>
          </Grid>
          <Button
            type='submit'
            variant='contained'
            sx={{
              width: isMobile ? '100%' : '430px',
              height: '48px',
              backgroundColor: '#1E2772',
              mt: 4,
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              color: '#FFFFFF',
            }}
          >
            Login Now
          </Button>
        </form>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 4,
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "130px",
              borderRadius: "8px",
              textTransform: "none",
              fontFamily: "Poppins",
              fontSize: "16px",
              background: "green"
            }}
            onClick={()=>{navigate('/addmoneyNew')}}
          >
            Add Money
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "130px",
              borderRadius: "8px",
              textTransform: "none",
              fontFamily: "Poppins",
              fontSize: "16px",
              background: "green"
            }}
            onClick={()=>{navigate('/payoutnew')}}
          >
            Payout
          </Button>
        </Box> */}
      </Box>

    </Box>
  );
};

export default SignIn;
