
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import axios from "axios";

const SearchSaving = () => {
  const [branches, setBranches] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filters, setFilters] = useState({
    branch: "",
    fromDate: "",
    toDate: "",
    applicantName: "",
    accountNo: "",
    memberCode: "",
    advisorCode: "",
    schemeName: "",
  });
  const [isSearchClicked, setIsSearchClicked] = useState(false); // Tracks if search is clicked
  const componentRef = useRef();

  // Token for authorization
  const token = localStorage.getItem("token"); // Replace this with your actual token

  // Fetch branch list and account details on component mount
  useEffect(() => {
    // Fetch branches
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });

    // Fetch saving accounts
    axios
      .get("https://api.vlnidhi.com/api/admin/saving-accounts/searchaccount", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching account details:", error);
      });
  }, [token]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // Handle Search button click to filter the accounts
  const handleSearch = () => {
    const filtered = accounts.filter((account) => {
      const matchesBranch =
        filters.branch === "" || account.branchName === filters.branch;
      const matchesFromDate =
        filters.fromDate === "" ||
        new Date(account.openingDate) >= new Date(filters.fromDate);
      const matchesToDate =
        filters.toDate === "" ||
        new Date(account.openingDate) <= new Date(filters.toDate);
      const matchesApplicantName =
        filters.applicantName === "" ||
        account.accountHolderName
          .toLowerCase()
          .includes(filters.applicantName.toLowerCase());
      const matchesAccountNo =
        filters.accountNo === "" ||
        account.accountNo.includes(filters.accountNo);
      const matchesMemberCode =
        filters.memberCode === "" ||
        account.memberCode.includes(filters.memberCode);
      const matchesAdvisorCode =
        filters.advisorCode === "" ||
        (account.advisorCode &&
          account.advisorCode.includes(filters.advisorCode));
      const matchesSchemeName =
        filters.schemeName === "" ||
        account.savingSchemeName
          .toLowerCase()
          .includes(filters.schemeName.toLowerCase());

      return (
        matchesBranch &&
        matchesFromDate &&
        matchesToDate &&
        matchesApplicantName &&
        matchesAccountNo &&
        matchesMemberCode &&
        matchesAdvisorCode &&
        matchesSchemeName
      );
    });

    setFilteredAccounts(filtered);
    setIsSearchClicked(true); // Show table after search is clicked
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        "@media (max-width: 600px)": {
          padding: "10px",
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{ marginBottom: "20px", fontWeight: "bold" }}
      >
        Search Box
      </Typography>
      <Grid container spacing={2}>
        {/* Branch Dropdown */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "5px" }}>Branch</Typography>
            <TextField
              select
              fullWidth
              name="branch"
              value={filters.branch}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              sx={{
                width: "336px",
                height: "48px",
                borderRadius: "10px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              {branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>

        {/* From Date */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "5px" }}>From Date</Typography>
            <TextField
              type="date"
              fullWidth
              name="fromDate"
              value={filters.fromDate}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              sx={{
                width: "336px",
                height: "48px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            />
          </Box>
        </Grid>

        {/* To Date */}
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "5px" }}>To Date</Typography>
            <TextField
              type="date"
              fullWidth
              name="toDate"
              value={filters.toDate}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              sx={{
                width: "336px",
                height: "48px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            />
          </Box>
        </Grid>

        {/* Applicant Name */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="applicantName"
            label="Applicant Name"
            value={filters.applicantName}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{
              width: "336px",
              height: "48px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        </Grid>

        {/* Account No */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="accountNo"
            label="Account No"
            value={filters.accountNo}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{
              width: "336px",
              height: "48px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        </Grid>

        {/* Member Code */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="memberCode"
            label="Member Code"
            value={filters.memberCode}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{
              width: "336px",
              height: "48px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        </Grid>

        {/* Advisor/Collector Code */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="advisorCode"
            label="Advisor/Collector Code"
            value={filters.advisorCode}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{
              width: "336px",
              height: "48px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        </Grid>

        {/* Scheme Name */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="schemeName"
            label="Scheme Name"
            value={filters.schemeName}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{
              width: "336px",
              height: "48px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          gap: "16px",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{
            backgroundColor: "#DFBF41",
            color: "white",
            borderRadius: "10px",
            width: "156px",
          }}
        >
          Search
        </Button>

        {/* Print Button */}
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E49F3A",
                color: "white",
                borderRadius: "10px",
                width: "156px",
              }}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Box>

      {/* Conditionally Render Table only if Search Button is Clicked */}
      {isSearchClicked && (
        <Box sx={{ marginTop: "20px"}}>
          <Typography sx={{fontSize: "26px", fontWeight: "bold", mb: 2}}>Saving Data List</Typography>
          <TableContainer component={Paper}>
            <Table ref={componentRef} aria-label="simple table">
              <TableHead sx={{background: "#413AF1"}}>
                <TableRow>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Account No</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Account Holder Name</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Scheme Name</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Opening Date</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Branch</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Member Code</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Advisor Code</TableCell>
                  <TableCell sx={{color: "white", fontWeight: 'bold'}}>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAccounts.map((account) => (
                  <TableRow key={account.accountNo}>
                    <TableCell>{account.accountNo}</TableCell>
                    <TableCell>{account.accountHolderName}</TableCell>
                    <TableCell>{account.savingSchemeName}</TableCell>
                    <TableCell>{account.openingDate}</TableCell>
                    <TableCell>{account.branchName}</TableCell>
                    <TableCell>{account.memberCode}</TableCell>
                    <TableCell>{account.advisorCode || "N/A"}</TableCell>
                    <TableCell>{account.accountBalance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default SearchSaving;
