import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemText,
  Collapse,
  Toolbar,
  Typography,
  Box,
  CssBaseline,
  AppBar,
  Button,
  IconButton,
  ListItemButton,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Mail,
  Notifications,
  AccountCircle,
  Menu as MenuIcon,
} from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import AddMember from "../Pages/MemberSection/AddMember";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import SavingDepositWithdraw from "../Pages/SavingAccount/SavingDepositeWithdraw";
import WorkInProgress from "./WorkInProgress";
import AddMemberKyc from "../Pages/MemberSection/AddMemberKyc";
import MemberSummary from "../Pages/MemberSection/MemberSummary";
import SearchMember from "../Pages/MemberSection/SearchMember";
import SavingPlanMaster from "../Pages/SavingAccount/SavingPlanMaster";
import AddSbAccount from "../Pages/SavingAccount/AddSbAccount";
import AccountPassbook from "../Pages/SavingAccount/AccountPassbook";
import SavingStatement from "../Pages/SavingAccount/SavingStatement";
import SavingAccountClose from "../Pages/SavingAccount/SavingAccountClose";
import SearchSaving from "../Pages/SavingAccount/SearchSaving";
import SMSSearchSaving from "../Pages/SavingAccount/SMSSearchSaving";
import ServiceChargeDeduction from "../Pages/SavingAccount/ServiceChargeDeduction";
//import SearchSaving2 from "../Pages/SavingAccount/SearchSaving2";
import MemberApproval from "../Pages/ApprovalSection/MemberApproval";
import SavingTransactionApproval from "../Pages/ApprovalSection/SavingTransactionApproval";
// import MobileMemberApprovalReport from "../Pages/ApprovalSection/MobileMemberApprovalReport";

import vllogo from "../Assets/logo.png";

const Sidebar = () => {
  const [openMemberSection, setOpenMemberSection] = useState(false);
  const [openApprovalSection, setOpenApprovalSection] = useState(false);
  const [openSavingSection, setOpenSavingSection] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(<Dashboard />); // State for main area component

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMemberClick = () => {
    setOpenMemberSection(!openMemberSection);
  };

  const handleSavingClick = () => {
    setOpenSavingSection(!openSavingSection);
  };

  const handleApprovalClick = () => {
    setOpenApprovalSection(!openApprovalSection);
  };

  const handleMenuOpen = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor) => {
    setAnchor(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightDrawerOpen(!rightDrawerOpen);
  };

  const drawerWidth = 240;

  // Sidebar Drawer desktop view
  const drawer = (
    <Box
      sx={{
        backgroundColor: "#3029D9",
        height: "100%",
        color: "white",
        fontFamily: "Poppins",
      }}
    >
      <Toolbar sx={{ backgroundColor: "#3029D9", color: "white", display: "flex", justifyContent: 'center' }}>
        {/* <Typography variant="h6" noWrap component="div">
          Logo
        </Typography> */}
        <Box
          component="img"
          src={vllogo}
          alt="VL_Logo"
          style={{ width: "55px", height: "55px", marginBottom: "-10px" }}
        ></Box>
      </Toolbar>
      <List sx={{ background: "#3029D9" }}>
        {/* Dashboard */}

        <ListItemButton
          sx={{ ":hover": { background: "#E8C30B" } }}
          onClick={() => setSelectedComponent(<Dashboard />)}
        >
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        {/* Member Section */}

        <ListItemButton
          sx={{ ":hover": { background: "#E8C30B" } }}
          onClick={handleMemberClick}
        >
          <ListItemText primary="Member Section" />
          {openMemberSection ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openMemberSection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => setSelectedComponent(<AddMember />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Add Member" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<AddMemberKyc />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Add Member KYC" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<MemberSummary />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Member Summary" />
            </ListItemButton>
            {/* <ListItemButton
              onClick={() => setSelectedComponent(<WorkInProgress />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Member Report" />
            </ListItemButton> */}
            <ListItemButton
              onClick={() => setSelectedComponent(<SearchMember />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Search Report" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Saving Account */}

        <ListItemButton
          sx={{ ":hover": { background: "#E8C30B" } }}
          onClick={handleSavingClick}
        >
          <ListItemText primary="Saving Account" />
          {openSavingSection ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSavingSection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => setSelectedComponent(<SavingPlanMaster />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Savings Plan Master" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<AddSbAccount />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Savings Opening" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<SavingDepositWithdraw />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Savings Transaction" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<AccountPassbook />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Savings Passbook" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<SavingStatement />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Savings Statement" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<SavingAccountClose />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Account Closer" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<SearchSaving />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Search Savings AC" />
            </ListItemButton>
            {/* <ListItemButton
              onClick={() => setSelectedComponent(<SearchSaving2 />)}
              sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
            >
              <ListItemText primary="Search Savings AC 2" />
            </ListItemButton> */}
            <ListItemButton
              onClick={() => setSelectedComponent(<SMSSearchSaving />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="SMS Charges Deduction" />
            </ListItemButton>
            <ListItemButton
              onClick={() => setSelectedComponent(<ServiceChargeDeduction />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Service Charges Deduction" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Approval Section */}

        <ListItemButton
          sx={{ ":hover": { background: "#E8C30B" } }}
          onClick={handleApprovalClick}
        >
          <ListItemText primary="Approval Section" />
          {openApprovalSection ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openApprovalSection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => setSelectedComponent(<MemberApproval />)}
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Member Approval" />
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                setSelectedComponent(<SavingTransactionApproval />)
              }
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Saving Transaction Approval" />
            </ListItemButton>
            {/* <ListItemButton
              onClick={() =>
                setSelectedComponent(<MobileMemberApprovalReport />)
              }
              sx={{
                pl: 4,
                height: "43px",
                ":hover": { background: "#E8C30B" },
              }}
            >
              <ListItemText primary="Mobile Member Approval Report" />
            </ListItemButton> */}
          </List>
        </Collapse>
      </List>
    </Box>
  );
  // AppBar Drawer For mobile view
  const rightDrawer = (
    <Box
      sx={{
        width: drawerWidth,
        backgroundColor: "#3029D9",
        height: "100%",
        color: "white",
      }}
    >
      <List>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="Configuration" />
        </ListItemButton>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="NDH Report" />
        </ListItemButton>
        <ListItemButton onClick={handleRightDrawerToggle}>
          <ListItemText primary="Collection Sheet" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#3029D9",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}

          {!isMobile && (
            <>
              <Button sx={{ color: "white", textTransform: "none" }}>
                Home
              </Button>
              <Button
                sx={{ color: "white", textTransform: "none" }}
                aria-controls="configuration-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, setAnchorEl)}
                endIcon={<ArrowDropDownIcon />}
              >
                Configuration
              </Button>
              <Button
                sx={{ color: "white", textTransform: "none" }}
                aria-controls="ndh-report-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, setAnchorEl2)}
                endIcon={<ArrowDropDownIcon />}
              >
                NDH Report
              </Button>
              <Button
                sx={{ color: "white", textTransform: "none" }}
                aria-controls="collection-sheet-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuOpen(e, setAnchorEl3)}
                endIcon={<ArrowDropDownIcon />}
              >
                Collection Sheet
              </Button>
            </>
          )}

          {/* AppBar Right-side Icons */}
          <Box sx={{ flexGrow: 1 }} />
          {/* <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <Button
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "white",
              }}
              onClick={() => {
                navigate("/addmoneyNew");
              }}
            >
              Add Money
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "white",
              }}
              onClick={() => {
                navigate("/payoutnew");
              }}
            >
              Payout
            </Button>
          </Box> */}
          <IconButton color="inherit">
            <Mail />
          </IconButton>
          <IconButton color="inherit">
            <Notifications />
          </IconButton>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>

          {/* Logout button */}
          <IconButton
            onClick={() => {
              // localStorage.removeItem("token");
              // localStorage.removeItem("roles");
              // localStorage.removeItem("username");
              localStorage.clear();
              navigate("/");
              toast.success("Successfully logged out!");
            }}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>

          {/* Mobile Menu Icon for AppBar Items */}
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              edge="end"
              onClick={handleRightDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar for larger screens */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>

      {/* Sidebar for mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Right Drawer for mobile view */}
      <Drawer
        anchor="right"
        open={rightDrawerOpen}
        onClose={handleRightDrawerToggle}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {rightDrawer}
      </Drawer>

      {/* Main Area */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, py: 12, height: "100%" }}>
        {selectedComponent}
      </Box>
    </Box>
  );
};

export default Sidebar;






// import React, { useState } from "react";
// import {
//   Drawer,
//   List,
//   ListItemText,
//   Collapse,
//   Toolbar,
//   Typography,
//   Box,
//   CssBaseline,
//   AppBar,
//   Button,
//   IconButton,
//   ListItemButton,
// } from "@mui/material";
// import {
//   ExpandLess,
//   ExpandMore,
//   Mail,
//   Notifications,
//   AccountCircle,
//   Menu as MenuIcon,
// } from "@mui/icons-material";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { useTheme, useMediaQuery } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import Dashboard from "../Pages/Dashboard";
// import AddMember from "../Pages/MemberSection/AddMember";
// import LogoutIcon from "@mui/icons-material/Logout";
// import { toast } from "react-toastify";
// import SavingDepositWithdraw from "../Pages/SavingAccount/SavingDepositeWithdraw";
// import WorkInProgress from "./WorkInProgress";
// import AddMemberKyc from "../Pages/MemberSection/AddMemberKyc";
// import MemberSummary from "../Pages/MemberSection/MemberSummary";
// import SearchMember from "../Pages/MemberSection/SearchMember";
// import SavingPlanMaster from "../Pages/SavingAccount/SavingPlanMaster";
// import AddSbAccount from "../Pages/SavingAccount/AddSbAccount";
// import AccountPassbook from "../Pages/SavingAccount/AccountPassbook";
// import SavingStatement from "../Pages/SavingAccount/SavingStatement";
// import SavingAccountClose from "../Pages/SavingAccount/SavingAccountClose";
// import SearchSaving from "../Pages/SavingAccount/SearchSaving";
// import SMSSearchSaving from "../Pages/SavingAccount/SMSSearchSaving";
// import ServiceChargeDeduction from "../Pages/SavingAccount/ServiceChargeDeduction";
// // import SearchSaving2 from "../Pages/SavingAccount/SearchSaving2";
// import MemberApproval from "../Pages/ApprovalSection/MemberApproval";
// import SavingTransactionApproval from "../Pages/ApprovalSection/SavingTransactionApproval";
 
// const Sidebar = () => {
//   const [openMemberSection, setOpenMemberSection] = useState(false);
//   const [openApprovalSection, setOpenApprovalSection] = useState(false);
//   const [openSavingSection, setOpenSavingSection] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [anchorEl2, setAnchorEl2] = useState(null);
//   const [anchorEl3, setAnchorEl3] = useState(null);
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
//   const [selectedComponent, setSelectedComponent] = useState(<Dashboard />); // State for main area component
 
//   const navigate = useNavigate();
 
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
 
//   const handleMemberClick = () => {
//     setOpenMemberSection(!openMemberSection);
//   };
 
//   const handleSavingClick = () => {
//     setOpenSavingSection(!openSavingSection);
//   };
 
//   const handleApprovalClick = () => {
//     setOpenApprovalSection(!openApprovalSection);
//   };
 
//   const handleMenuOpen = (event, setAnchor) => {
//     setAnchor(event.currentTarget);
//   };
 
//   const handleMenuClose = (setAnchor) => {
//     setAnchor(null);
//   };
 
//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };
 
//   const handleRightDrawerToggle = () => {
//     setRightDrawerOpen(!rightDrawerOpen);
//   };
  
 
//   const drawerWidth = 240;
 
//   // Sidebar Drawer desktop view
//   const drawer = (
//     <Box
//       sx={{
//         backgroundColor: "#3029D9",
//         height: "100%",
//         color: "white",
//         fontFamily: "Poppins",
//       }}
//     >
//       <Toolbar sx={{ backgroundColor: "#3029D9", color: "white" }}>
//         <Typography variant="h6" noWrap component="div">
//           Logo
//         </Typography>
//       </Toolbar>
//       <List sx={{background: "#3029D9"}}>
 
//         {/* Dashboard */}
 
//         <ListItemButton sx={{ ":hover": { background: "#E8C30B"} }} onClick={() => setSelectedComponent(<Dashboard />)}>
//           <ListItemText primary="Dashboard" />
//         </ListItemButton>
 
//         {/* Member Section */}
 
//         <ListItemButton sx={{ ":hover": { background: "#E8C30B"} }} onClick={handleMemberClick}>
//           <ListItemText primary="Member Section" />
//           {openMemberSection ? <ExpandLess /> : <ExpandMore />}
//         </ListItemButton>
//         <Collapse in={openMemberSection} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<AddMember />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Add Member" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<AddMemberKyc />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Add Member KYC" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<MemberSummary />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Member Summary" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<WorkInProgress />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Member Report" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SearchMember />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Search Report" />
//             </ListItemButton>
//           </List>
//         </Collapse>
 
//         {/* Saving Account */}
 
//         <ListItemButton sx={{ ":hover": { background: "#E8C30B"} }} onClick={handleSavingClick}>
//           <ListItemText primary="Saving Account" />
//           {openSavingSection ? <ExpandLess /> : <ExpandMore />}
//         </ListItemButton>
//         <Collapse in={openSavingSection} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SavingPlanMaster />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Savings Plan Master" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<AddSbAccount />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Savings Opening" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SavingDepositWithdraw />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Savings Transaction" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<AccountPassbook />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Savings Passbook" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SavingStatement />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Savings Statement" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SavingAccountClose />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Account Closer" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SearchSaving />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Search Savings AC" />
//             </ListItemButton>
//             {/* <ListItemButton
//               onClick={() => setSelectedComponent(<SearchSaving2 />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Search Savings AC 2" />
//             </ListItemButton> */}
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SMSSearchSaving />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="SMS Charges Deduction" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<ServiceChargeDeduction />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Service Charges Deduction" />
//             </ListItemButton>
//           </List>
//         </Collapse>
 
//         {/* Approval Section */}
 
//         <ListItemButton sx={{ ":hover": { background: "#E8C30B"} }} onClick={handleApprovalClick}>
//           <ListItemText primary="Approval Section" />
//           {openApprovalSection ? <ExpandLess /> : <ExpandMore />}
//         </ListItemButton>
//         <Collapse in={openApprovalSection} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<MemberApproval />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Member Approval" />
//             </ListItemButton>
//             <ListItemButton
//               onClick={() => setSelectedComponent(<SavingTransactionApproval />)}
//               sx={{ pl: 4, height: "43px", ":hover": { background: "#E8C30B"} }}
//             >
//               <ListItemText primary="Saving Transaction Approval" />
//             </ListItemButton>
//           </List>
//         </Collapse>
//       </List>
//     </Box>
//   );
//   // AppBar Drawer For mobile view
//   const rightDrawer = (
//     <Box
//       sx={{
//         width: drawerWidth,
//         backgroundColor: "#3029D9",
//         height: "100%",
//         color: "white",
//       }}
//     >
//       <List>
//         <ListItemButton onClick={handleRightDrawerToggle}>
//           <ListItemText primary="Home" />
//         </ListItemButton>
//         <ListItemButton onClick={handleRightDrawerToggle}>
//           <ListItemText primary="Configuration" />
//         </ListItemButton>
//         <ListItemButton onClick={handleRightDrawerToggle}>
//           <ListItemText primary="NDH Report" />
//         </ListItemButton>
//         <ListItemButton onClick={handleRightDrawerToggle}>
//           <ListItemText primary="Collection Sheet" />
//         </ListItemButton>
//       </List>
//     </Box>
//   );
 
//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         sx={{
//           backgroundColor: "#3029D9",
//           width: { sm: `calc(100% - ${drawerWidth}px)` },
//           ml: { sm: `${drawerWidth}px` },
//         }}
//       >
//         <Toolbar>
//           {isMobile && (
//             <IconButton
//               color="inherit"
//               aria-label="open drawer"
//               edge="start"
//               onClick={handleDrawerToggle}
//               sx={{ mr: 2, display: { sm: "none" } }}
//             >
//               <MenuIcon />
//             </IconButton>
//           )}
 
//           {!isMobile && (
//             <>
//               <Button sx={{ color: "white", textTransform: "none" }}>
//                 Home
//               </Button>
//               <Button
//                 sx={{ color: "white", textTransform: "none" }}
//                 aria-controls="configuration-menu"
//                 aria-haspopup="true"
//                 onClick={(e) => handleMenuOpen(e, setAnchorEl)}
//                 endIcon={<ArrowDropDownIcon />}
//               >
//                 Configuration
//               </Button>
//               <Button
//                 sx={{ color: "white", textTransform: "none" }}
//                 aria-controls="ndh-report-menu"
//                 aria-haspopup="true"
//                 onClick={(e) => handleMenuOpen(e, setAnchorEl2)}
//                 endIcon={<ArrowDropDownIcon />}
//               >
//                 NDH Report
//               </Button>
//               <Button
//                 sx={{ color: "white", textTransform: "none" }}
//                 aria-controls="collection-sheet-menu"
//                 aria-haspopup="true"
//                 onClick={(e) => handleMenuOpen(e, setAnchorEl3)}
//                 endIcon={<ArrowDropDownIcon />}
//               >
//                 Collection Sheet
//               </Button>
//             </>
//           )}
 
//           {/* AppBar Right-side Icons */}
//           <Box sx={{ flexGrow: 1 }} />
//           <IconButton color="inherit">
//             <Mail />
//           </IconButton>
//           <IconButton color="inherit">
//             <Notifications />
//           </IconButton>
//           <IconButton color="inherit">
//             <AccountCircle />
//           </IconButton>
 
//           {/* Logout button */}
//           <IconButton
//             onClick={() => {
//               // localStorage.removeItem("token");
//               // localStorage.removeItem("roles");
//               // localStorage.removeItem("username");
//               localStorage.clear();
//               navigate("/");
//               toast.success("Successfully logged out!");
//             }}
//             color="inherit"
//           >
//             <LogoutIcon />
//           </IconButton>
 
//           {/* Mobile Menu Icon for AppBar Items */}
//           {isMobile && (
//             <IconButton
//               color="inherit"
//               aria-label="open right drawer"
//               edge="end"
//               onClick={handleRightDrawerToggle}
//             >
//               <MenuIcon />
//             </IconButton>
//           )}
//         </Toolbar>
//       </AppBar>
 
//       {/* Sidebar for larger screens */}
//       <Drawer
//         variant="permanent"
//         sx={{
//           display: { xs: "none", sm: "block" },
//           width: drawerWidth,
//           flexShrink: 0,
//           "& .MuiDrawer-paper": {
//             width: drawerWidth,
//             boxSizing: "border-box",
//           },
//         }}
//         open
//       >
//         {drawer}
//       </Drawer>
 
//       {/* Sidebar for mobile */}
//       <Drawer
//         variant="temporary"
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//         sx={{
//           display: { xs: "block", sm: "none" },
//           "& .MuiDrawer-paper": {
//             width: drawerWidth,
//             boxSizing: "border-box",
//           },
//         }}
//       >
//         {drawer}
//       </Drawer>
 
//       {/* Right Drawer for mobile view */}
//       <Drawer
//         anchor="right"
//         open={rightDrawerOpen}
//         onClose={handleRightDrawerToggle}
//         sx={{
//           display: { xs: "block", sm: "none" },
//           "& .MuiDrawer-paper": {
//             width: drawerWidth,
//             boxSizing: "border-box",
//           },
//         }}
//       >
//         {rightDrawer}
//       </Drawer>
 
//       {/* Main Area */}
//       <Box component="main" sx={{ flexGrow: 1, p: 3, py: 12, height: "100%" }}>
//         {selectedComponent}
//       </Box>
//     </Box>
//   );
// };
 
// export default Sidebar;