import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, Grid, Paper, CircularProgress, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-toastify';

const payoutModes = [
    { value: 'NEFT', label: 'NEFT' },
    { value: 'RTGS', label: 'RTGS' },
    { value: 'IMPS', label: 'IMPS' },
];

const PayoutSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    payeeaccountNumber: Yup.string().required('Payee Account Number is required'),
    bankIfsc: Yup.string().required('Bank IFSC is required'),
    transferMode: Yup.string().required('Transfer Mode is required'),
    transferAmount: Yup.number().required('Transfer Amount is required').positive('Amount must be positive'),
    remarks: Yup.string(),
});

const PayoutScreen = () => {
    const [isGeneratingKey, setIsGeneratingKey] = useState(false);

    const handleGenerateKey = async (values) => {
        setIsGeneratingKey(true);
        try {
            const response = await axios.post("https://api.ipaisa.co.in/myapp/ex/genratekey", values);

            if (response.data && response.data.key) {
                const redirectUrl = `https://ipaisa.co.in/deeplinkpayout/${response.data.key}`;

                // Open the URL in a new window
                const newWindow = window.open(redirectUrl, "_blank");
                if (newWindow) {
                    const checkWindowClosed = setInterval(() => {
                        if (newWindow.closed) {
                            clearInterval(checkWindowClosed);
                            setTimeout(() => {
                                // handlePaymentStatus(values.txnid); // Assuming you have txnid in values
                            }, 1000); // Adjust the timeout as needed
                        }
                    }, 1000); // Check every second if the window is closed

                    // Set a timer to close the window after 2 minutes
                    setTimeout(() => {
                        if (!newWindow.closed) {
                            newWindow.close();
                            console.log("Payment window closed after 2 minutes");
                        }
                    }, 120000); // 2 minutes in milliseconds
                }
            } else {
                console.error("Received data:", response.data);
            }
        } catch (error) {
            console.error("Error calling the API:", error);
            // Handle error appropriately
        } finally {
            setIsGeneratingKey(false);
        }
    };

    return (
        <Box sx={{ p: 4, marginTop: '7%' }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>Payout</Typography>
                <Formik
                    initialValues={{
                        name: '',
                        payeeaccountNumber: '',
                        bankIfsc: '',
                        transferMode: '',
                        transferAmount: '',
                        remarks: '',
                    }}
                    validationSchema={PayoutSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleGenerateKey(values); // Pass values to handleGenerateKey
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={TextField}
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={TextField}
                                        name="payeeaccountNumber"
                                        label="Payee Account Number"
                                        variant="outlined"
                                        fullWidth
                                        error={touched.payeeaccountNumber && Boolean(errors.payeeaccountNumber)}
                                        helperText={touched.payeeaccountNumber && errors.payeeaccountNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={TextField}
                                        name="bankIfsc"
                                        label="Bank IFSC"
                                        variant="outlined"
                                        fullWidth
                                        error={touched.bankIfsc && Boolean(errors.bankIfsc)}
                                        helperText={touched.bankIfsc && errors.bankIfsc}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={TextField}
                                        name="transferMode"
                                        label="Transfer Mode"
                                        select
                                        variant="outlined"
                                        fullWidth
                                        error={touched.transferMode && Boolean(errors.transferMode)}
                                        helperText={touched.transferMode && errors.transferMode}
                                    >
                                        {payoutModes.map((mode) => (
                                            <MenuItem key={mode.value} value={mode.value}>
                                                {mode.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={TextField}
                                        name="transferAmount"
                                        label="Transfer Amount"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        error={touched.transferAmount && Boolean(errors.transferAmount)}
                                        helperText={touched.transferAmount && errors.transferAmount}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        as={TextField}
                                        name="remarks"
                                        label="Remarks"
                                        variant="outlined"
                                        fullWidth
                                        error={touched.remarks && Boolean(errors.remarks)}
                                        helperText={touched.remarks && errors.remarks}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }} gap={2}>
                                <Button
                                    type="submit"
                                    style={{
                                        backgroundColor: "#027BFE",
                                        color: "white",
                                        textTransform: "none",
                                        width: "50%",
                                        borderRadius: "9px",
                                        fontSize: '20px',
                                        height: '48px',
                                    }}
                                    disabled={isSubmitting || isGeneratingKey}
                                >
                                    {isGeneratingKey ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Pay Out'}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Box>
    );
};

export default PayoutScreen;
