import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import Swal from 'sweetalert2';

const MemberApproval = () => {
  const [branches, setBranches] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [branch, setBranch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch branches
    axios
      .get("https://api.vlnidhi.com/api/admin/getallbid", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setBranches(response.data))
      .catch((error) => console.error("Error fetching branches", error));
  }, [token]);

  const handleSearch = () => {
    // Filter members based on branch, fromDate, and toDate
    const filtered = pendingMembers.filter((member) => {
      const memberDate = new Date(member.registrationDate);
      const isWithinDateRange =
        (!fromDate || new Date(fromDate) <= memberDate) &&
        (!toDate || memberDate <= new Date(toDate));
      return member.branchName === branch && isWithinDateRange;
    });

    setFilteredMembers(filtered);
    setShowTable(true);
  };

  const handleGetAll = () => {
    // Fetch all pending members and display them
    axios
      .get("https://api.vlnidhi.com/api/admin/pending", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPendingMembers(response.data);
        setFilteredMembers(response.data);
        setShowTable(true);
      })
      .catch((error) => console.error("Error fetching members", error));
  };

  const handleApprove = () => {
    axios
      .put(
        "https://api.vlnidhi.com/api/admin/approve",
        selectedMembers, // Pass the array directly
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setFilteredMembers((prevMembers) =>
          prevMembers.filter(
            (member) => !selectedMembers.includes(member.memberCode)
          )
        );
        setSelectedMembers([]);
        setSelectAll(false);
        
        // Show success message using SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Member Approved Successfully!',
          text: response.data.message,
          confirmButtonText: 'OK'
        });
      })
      .catch((error) => console.error("Error approving members", error));
  };

  const handleReject = () => {
    axios
      .put(
        "https://api.vlnidhi.com/api/admin/reject",
        selectedMembers, // Pass the array directly
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setFilteredMembers((prevMembers) =>
          prevMembers.filter(
            (member) => !selectedMembers.includes(member.memberCode)
          )
        );
        setSelectedMembers([]);
        setSelectAll(false);

        Swal.fire({
          icon: 'success',
          title: 'Member reject!',
          text: response.data.message,
          confirmButtonText: 'OK'
        });
      })
      .catch((error) => console.error("Error rejecting members", error));
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allMemberCodes = filteredMembers.map(
        (member) => member.memberCode
      );
      setSelectedMembers(allMemberCodes);
    } else {
      setSelectedMembers([]);
    }
  };

  const handleSelectMember = (memberCode) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(memberCode)
        ? prevSelected.filter((code) => code !== memberCode)
        : [...prevSelected, memberCode]
    );
  };
 
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ fontSize: "26px", fontWeight: "bold", mb: 2, mt: 2 }}>
        Member Approval
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "#f7f8fc",
          p: 4,
          borderRadius: "8px",
        }}
      >
        {/* Search Box */}
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>Search Box</Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Branch Field */}
          <Grid>
            <Typography>Branch</Typography>
            <TextField
              variant="outlined"
              select
              sx={{ width: "297px" }}
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
            >
              {branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
 
          <Grid>
            <Typography>From Date</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={{ height: "48px", width: "297px" }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>
          <Grid>
            <Typography>To Date</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              sx={{ height: "48px", width: "297px" }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
          <Grid>
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                height: "48px",
                width: "150px",
                background: "#DFBF41",
                color: "white",
                marginTop: "3px",
                borderRadius: "10px",
                mt: 3,
              }}
            >
              Search
            </Button>
          </Grid>
          {/* Get All Button */}
          <Grid>
            <Button
              variant="contained"
              onClick={handleGetAll}
              sx={{
                height: "48px",
                width: "150px",
                background: "green",
                color: "white",
                marginTop: "16px",
                borderRadius: "10px",
                alignSelf: "flex-start",
                mt: 3,
              }}
            >
              Get All
            </Button>
          </Grid>
        </Box>
      </Box>
 
      {/* Table Data */}
      {showTable && (
        <Box sx={{ mt: 3, p: 2 }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
            Saving Txn Data List
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: "#3c3cc1" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      Sl No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      M. Code
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      M. Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      Gender
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      D.O.J.
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      Phone No
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      Fees
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold", textAlign: "center" }}>
                      Share
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMembers.map((member, index) => (
                    <TableRow key={member.memberCode}>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Checkbox
                          checked={selectedMembers.includes(member.memberCode)}
                          onChange={() => handleSelectMember(member.memberCode)}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{member.memberCode}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{member.memberName}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{member.gender}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {new Date(member.registrationDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{member.mobileNo}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{member.memberfees}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>{member.noOfShare}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
 
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                onClick={handleApprove}
                disabled = {selectedMembers.length === 0}
                sx={{
                  height: "40px",
                  width: "100px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                onClick={handleReject}
                disabled = {selectedMembers.length === 0}
                sx={{
                  height: "40px",
                  width: "100px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                Reject
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
 
export default MemberApproval;












// import React, { useState } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Checkbox,
//   Typography,
//   InputAdornment,
// } from "@mui/material";

// const MemberApproval = () => {
//   const [showTable, setShowTable] = useState(false);

//   const handleSearch = () => {
//     setShowTable(true);
//   };

//   return (
//     <Box sx={{ p: 2 }}>
//       <Typography sx={{ fontSize: "26px", fontWeight: "bold", mb: 2, mt: 2 }}>
//         Member Approval
//       </Typography>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "16px",
//           backgroundColor: "#f7f8fc",
//           p: 4,
//           borderRadius: "8px",
//         }}
//       >
//         {/* Search Box */}
//         <Typography sx={{ fontSize: "22px" }}>Search Box</Typography>

//         <Box sx={{ display: "flex", gap: 2 }}>
//           {/* Branch Field */}
//           <TextField
//             label="Branch"
//             variant="outlined"
//             select
//             sx={{ width: "360px" }}
//           >
//             <MenuItem value={"Branch1"}>Branch 1</MenuItem>
//             <MenuItem value={"Branch2"}>Branch 2</MenuItem>
//           </TextField>

//           <TextField
//             fullWidth
//             variant="outlined"
//             label="From Date"
//             type="date"
//             sx={{ height: "48px", width: "360px" }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   {/* <CalendarTodayIcon /> */}
//                 </InputAdornment>
//               ),
//               inputProps: {
//                 pattern: "\\d{4}-\\d{2}-\\d{2}",
//               },
//             }}
//           />
//           <TextField
//             fullWidth
//             variant="outlined"
//             label="To Date"
//             type="date"
//             sx={{ height: "48px", width: "360px" }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   {/* <CalendarTodayIcon /> */}
//                 </InputAdornment>
//               ),
//               inputProps: {
//                 pattern: "\\d{4}-\\d{2}-\\d{2}",
//               },
//             }}
//           />
//           <Button
//             variant="contained"
//             onClick={handleSearch}
//             sx={{
//               height: "48px",
//               width: "150px",
//               background: "#DFBF41",
//               color: "white",
//               marginTop: "3px",
//               borderRadius: "10px",
//             }}
//           >
//             Search
//           </Button>
//         </Box>
//       </Box>

//       {/* Table Data */}
//       {showTable && (
//         <Box sx={{ mt: 3, p: 2 }}>
//           <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
//             Saving Txn Data List
//           </Typography>
//           <Box sx={{ mt: 3 }}>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead sx={{ backgroundColor: "#3c3cc1" }}>
//                   <TableRow>
//                     <TableCell padding="checkbox">
//                       <Checkbox sx={{ color: "white" }} />
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Sl No
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       M. Code
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       M. Name
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Gender
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       D.O.J.
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Phone No
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Fees
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Share
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell padding="checkbox">
//                       <Checkbox />
//                     </TableCell>
//                     <TableCell>01</TableCell>
//                     <TableCell>VL00104</TableCell>
//                     <TableCell>ANKITA</TableCell>
//                     <TableCell>Female</TableCell>
//                     <TableCell>16/08/2024</TableCell>
//                     <TableCell>8010463981</TableCell>
//                     <TableCell>0</TableCell>
//                     <TableCell>10</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <Box
//               sx={{
//                 mt: 2,
//                 display: "flex",
//                 gap: 2,
//                 justifyContent: "flex-end",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#d9534f",
//                   color: "#fff",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Reject
//               </Button>
//               <Button
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#f3c13a",
//                   color: "#fff",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Approve
//               </Button>
//             </Box>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default MemberApproval;
