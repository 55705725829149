import axios from "axios";
import { toast } from "react-toastify";

export const loginUser = (userData) => async (dispatch) => {
  console.log(userData);
  try {
    const response = await axios.post(
      // "http://localhost:9191/api/admin/login",
      "https://api.vlnidhi.com/api/public/login",
      userData
    );
    const { Token, username, roles } = response.data;

    localStorage.setItem("token", Token);
    localStorage.setItem("username", username);
    localStorage.setItem("roles", JSON.stringify(roles));

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { Token, username, roles },
    });

    // Navigate to dashboard after successful login
    toast.success("Login Successful!");
    // navigate('/dashboard');
    window.location.href = "/dashboard"; // Use window.location to force a redirect
  } catch (error) {
    dispatch({
      type: "LOGIN_FAIL",
      payload: error.message,
    });
    // Show error toast
    toast.error("Login Failed: Invalid username or password");
  }
};
