import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Card,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Chart from '../Assets/Dashboard/Dummychart.png'
import Graph from '../Assets/Dashboard/Dummygraph.png'

const Dashboard = () => {
  return (
    <Box sx={{ p: 0 }}>
      {/* Search Filters */}
      <Card
        sx={{
          mb: 4,
          display: "flex",
          gap: 2,
          alignItems: "center",
          p: 3,
          borderRadius: 5,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "bold",
              mb: 1,
            }}
          >
            Branch
          </Typography>
          <TextField
            variant="outlined"
            InputProps={{ sx: { borderRadius: 3, width: "240px", height: "48px" } }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "bold",
              mb: 1,
            }}
          >
            From Date
          </Typography>
          <TextField
            //   label="From Date"
              type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={{ width: "240px" }}
            InputProps={{ sx: { borderRadius: 3, width: "240px", height: "48px" } }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "bold",
              mb: 1,
            }}
          >
            To Date
          </Typography>
          <TextField
            //   label="From Date"
              type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={{ width: "240px" }}
            InputProps={{ sx: { borderRadius: 3, width: "240px", height: "48px" } }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Button
            variant="contained"
            // startIcon={<Search />}
            sx={{
              height: "48px",
              paddingX: 4,
              background: "#E8C30B",
              borderRadius: "8px",
              textTransform: "none",

              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              width: "240px",
              mt: 3
            }}
          >
            Search
          </Button>
        </Box>
      </Card>

      {/* Info Cards */}
      <Grid container spacing={2}>
  {[
    {
      title: "Wallet Balance",
      amount: "Amount",
      type: "NEFT Balance",
      gradient: "linear-gradient(135deg, #1abc9c 30%, #16a085 100%)",
    },
    {
      title: "Total Balance",
      amount: "Balance",
      type: "Total Count",
      gradient: "linear-gradient(135deg, #9b59b6 30%, #8e44ad 100%)",
    },
    {
      title: "Total Transfer",
      amount: "Balance",
      type: "Total Count",
      gradient: "linear-gradient(135deg, #e74c3c 30%, #c0392b 100%)",
    },
    {
      title: "Card Transaction",
      amount: "IMPS Balance",
      type: "Total Count",
      gradient: "linear-gradient(135deg, #e67e22 30%, #d35400 100%)",
    },
    {
      title: "Cash Balance",
      amount: "Lorem ipsum",
      type: "Lorem ipsum",
      gradient: "linear-gradient(135deg, #9b59b6 30%, #8e44ad 100%)",
    },
    {
      title: "Lorem Ipsum",
      amount: "IMPS Balance",
      type: "Total Count",
      gradient: "linear-gradient(135deg, #3498db 30%, #2980b9 100%)",
    },
  ].map((card, index) => (
    <Grid item xs={12} md={4} lg={4} key={index}>
      <Paper
        sx={{
          background: card.gradient, // Apply the gradient background
          borderRadius: 4, // Rounded corners
          padding: 3,
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "120px", // Set a minimum height for the card
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for a floating effect
          overflow: "hidden",
          position: "relative", // For background elements
        }}
      >
        {/* Add an Icon or Background Shape */}
        <Box
          sx={{
            position: "absolute",
            top: "20px", // Adjust the position as needed
            left: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Icon Placeholder */}
          <Typography sx={{ fontSize: "24px", color: "#fff" }}>💰</Typography>
        </Box>

        {/* Content of the Card */}
        <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
          {card.title}
        </Typography>
        <Typography variant="body2">{card.amount}</Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          0
        </Typography>
        <Typography variant="body2">{card.type}</Typography>
      </Paper>
    </Grid>
  ))}
</Grid>

      {/* Statistics Section */}
      <Grid container spacing={2} sx={{ mt: 4 }}>
      
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2, borderRadius: 2 }}>
          <Typography variant="h6">Statistics</Typography>
          <Typography variant="body2">Lorem Ipsum</Typography>
          
          {/* Responsive Box for Image */}
          <Box
            sx={{
              height: { xs: 200, md: 250 }, // Responsive height
              width: '100%', // Full width for the container
              backgroundColor: "#fff",
              mt: 2,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
            component="img"
            src={Graph}
            alt="Graph"
          />
        </Paper>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2, borderRadius: 2 }}>
          <Typography variant="h6">Statistics</Typography>
          <Typography variant="body2">Lorem Ipsum</Typography>
          
          {/* Responsive Box for Image */}
          <Box
            sx={{
              height: { xs: 200, md: 250 }, // Responsive height
              width: '100%', // Full width for the container
              backgroundColor: "#fff",
              mt: 2,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
            component="img"
            src={Chart}
            alt="Chart"
          />
        </Paper>
      </Grid>

    </Grid>
    </Box>
  );
};

export default Dashboard;
