import {
  Container,
  Paper,
  Grid,
  Typography,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const SavingPlanMaster = () => {
  const [schemeData, setSchemeData] = useState({
    schemeName: '',
    effectiveDate: '',
    schemeCreatedBy: '',
    annualRoi: '',
    minOpeningAmount: '',
    monthlyAvgBalance:'',
    lockInBalance: '',
    smsCharges: '',
    smsInterval: '',
    freeIfscCollection: '',
    singleTxnLimit:'',
    dailyMaxLimit: '',
    weeklyMaxLimit: '',
    monthlyMaxLimit: '',
    serviceCharges: '',
  });

  const [savingPlans, setSavingPlans] = useState([]);
  const token = localStorage.getItem('token');

  const handleInputChange = (e) => {
    setSchemeData({
      ...schemeData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://api.vlnidhi.com/api/admin/savings-account-schemes/add-saving-account-schemes',
        schemeData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        // Fetch the updated list after adding a new scheme
        fetchSavingPlans();
      }
    } catch (error) {
      console.error('Error adding saving plan:', error);
    }
  };

  const fetchSavingPlans = async () => {
    try {
      const response = await axios.get(
        'https://api.vlnidhi.com/api/admin/savings-account-schemes/get-all-saving-account-schemes',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSavingPlans(response.data);
    } catch (error) {
      console.error('Error fetching saving plans:', error);
    }
  };

  useEffect(() => {
    fetchSavingPlans();
  }, []);

      
  return (
   
    <Box >
      <Typography 
        sx={{ 
          marginTop: { xs: '5%', md: '2%' }, 
          fontSize: { xs: '20px', md: '24px' }, 
          fontWeight: 600, 
          lineHeight: '36px', 
          textAlign: 'start' 
        }}
      >
        Saving Plan Master
      </Typography>

      <Paper sx={{ padding: '2%', marginTop: '2%' }}>
        <Typography 
          sx={{ 
            fontSize: { xs: '18px', md: '20px' }, 
            fontWeight: 500, 
            marginBottom: '2%' 
          }}
        >
          Saving Plan Master
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Scheme Name*
            </Typography>
            <TextField
              fullWidth
              name="schemeName"
              value={schemeData.schemeName}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Effective Date*
            </Typography>
            <TextField
              fullWidth
              type="date"
              name="effectiveDate"
              value={schemeData.effectiveDate}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Scheme Created By*
            </Typography>
            <TextField
              fullWidth
              name="schemeCreatedBy"
              value={schemeData.schemeCreatedBy}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Annual R.O.I (%)*
            </Typography>
            <TextField
              fullWidth
              name="annualRoi"
              value={schemeData.annualRoi}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Min. Opening Amount*
            </Typography>
            <TextField
              fullWidth
              name="minOpeningAmount"
              value={schemeData.minOpeningAmount}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Monthly Avg. Bal.*
            </Typography>
            <TextField
              fullWidth
              name="monthlyAvgBalance"
              value={schemeData.monthlyAvgBalance}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Lock In balance*
            </Typography>
            <TextField
              fullWidth
              name="lockInBalance"
              value={schemeData.lockInBalance}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              SMS Charges *
            </Typography>
            <TextField
              fullWidth
              name="smsCharges"
              value={schemeData.smsCharges}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>

        <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                    }}
                  >
                  SMS Interval*
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    name="smsInterval"  
                    value={schemeData.smsInterval}
                    onChange={handleInputChange}        
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                    <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                  </TextField>
         </Grid>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              SFree IFSC Collection(p.m)*
            </Typography>
            <TextField
              fullWidth
              name="freeIfscCollection"
              value={schemeData.freeIfscCollection}
              onChange={handleInputChange}        
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
            Free Transfers(p.m)*
            </Typography>
            <TextField
              fullWidth
              name='freeTransfers'
              value={schemeData.freeTransfers}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Single Txn. Limit*
            </Typography>
            <TextField
              fullWidth
              name='singleTxnLimit'
              value={schemeData.singleTxnLimit}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
             Daily MAX Limit*
            </Typography>
            <TextField
              fullWidth
              name='dailyMaxLimit'
              value={schemeData.dailyMaxLimit}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
             Weekly MAX Limit*
            </Typography>
            <TextField
              fullWidth
              name='weeklyMaxLimit'
              value={schemeData.weeklyMaxLimit}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Monthly MAX Limit*
            </Typography>
            <TextField
              fullWidth
              name='monthlyMaxLimit'
              value={schemeData.monthlyMaxLimit}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
             Service Charges *
            </Typography>
            <TextField
              fullWidth
              name='serviceCharges'
              value={schemeData.serviceCharges}
              onChange={handleInputChange}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>

        <Typography
                    sx={{
                      width: "100%",
                      fontSize: { xs: "16px", md: "18px" },
                      marginBottom: "8px",
                    }}
                  >
                 Service Interval*
                  </Typography>
                  <TextField
                    fullWidth
                    select
                    name="serviceInterval"  
                    value={schemeData.serviceInterval}
                    onChange={handleInputChange}        
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                    <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                  </TextField>
         </Grid>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              SFree IFSC Collection(p.m)*
            </Typography>
            <TextField
              fullWidth
              name="freeIfscCollection"
              value={schemeData.freeIfscCollection}
              onChange={handleInputChange}        
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
             Card Limit Monthly*
            </Typography>
            <TextField
              fullWidth
              name="cardLimitMonthly"
              value={schemeData.cardLimitMonthly}
              onChange={handleInputChange} 
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                width: '100%', 
                fontSize: { xs: '16px', md: '18px' }, 
                marginBottom: '8px' 
              }}
            >
              Card Total Limit Yearly*
            </Typography>
            <TextField
              fullWidth
              name="cardTotalLimitYearly"
              value={schemeData.cardTotalLimitYearly}
              onChange={handleInputChange} 
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  height: { xs: '40px', md: '48px' },
                },
              }}
            />
          </Grid>
        </Grid>
         {/* Save Button */}
      <Grid container justifyContent="flex-end" sx={{ marginTop: '2%' }}>
        <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
            width: { xs: '100%', md: '247px' },
            height: '48px',
            backgroundColor: '#1976d2',
            borderRadius: '10px',
            padding: '12px 103px',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '2px',
            }}
        >
            Save
        </Button>
        </Grid>
      </Paper>

       {/* Second Paper (Table Section) */}
    <Paper sx={{ padding: '2%', marginTop: '4%', borderRadius: '10px 10px 0 0' }}>
    <Typography 
          sx={{ 
            fontSize: { xs: '18px', md: '20px' }, 
            fontWeight: 500, 
            marginBottom: '2%' 
          }}
        >
         Scheme List
        </Typography>

    <TableContainer  component={Paper} sx={{ borderRadius: '12px' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ backgroundColor: "#3c3cc1" }}>
            <TableRow sx={{ backgroundColor: '#413AF1' }}>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Scheme Code</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Scheme Name</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Int. Rate (%)</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Opening Amount</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Avg. Balance</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Lock In Balance</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>SMS Mode</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>SMS Charges</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Card Charges</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Card Limit Mly</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Card Limit Yly</TableCell>
              <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {savingPlans.map((plan, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: { xs: '10px', md: '12px',fontWeight:800 }, textAlign: 'center' }}>{plan.schemeCode}</TableCell>
                <TableCell sx={{ textAlign: "center" ,fontSize:'16px'}}>{plan.schemeName}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.annualRoi}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.minOpeningAmount}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.monthlyAvgBalance}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.lockInBalance}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.smsInterval}</TableCell>
                <TableCell sx={{ textAlign: "center" ,fontSize:'16px'}}>{plan.smsCharges}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.cardCharge}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.cardLimitMonthly}</TableCell>
                <TableCell sx={{ textAlign: "center",fontSize:'16px' }}>{plan.cardTotalLimitYearly}</TableCell>
                <TableCell>
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton>
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
</Paper>
 </Box>
  );
}

export default SavingPlanMaster;









// import {
//   Container,
//   Paper,
//   Grid,
//   Typography,
//   MenuItem,
//   TextField,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
// } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';

// const SavingPlanMaster = () => {
//   const [schemeData, setSchemeData] = useState({
//     schemeName: '',
//     effectiveDate: '',
//     schemeCreatedBy: '',
//     annualRoi: '',
//     minOpeningAmount: '',
//     monthlyAvgBalance:'',
//     lockInBalance: '',
//     smsCharges: '',
//     smsInterval: '',
//     freeIfscCollection: '',
//     singleTxnLimit:'',
//     dailyMaxLimit: '',
//     weeklyMaxLimit: '',
//     monthlyMaxLimit: '',
//     serviceCharges: '',
//   });

//   const [savingPlans, setSavingPlans] = useState([]);
//   const token = localStorage.getItem('token');

//   const handleInputChange = (e) => {
//     setSchemeData({
//       ...schemeData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         'https://api.vlnidhi.com/api/admin/savings-account-schemes/add-saving-account-schemes',
//         schemeData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.status === 200) {
//         // Fetch the updated list after adding a new scheme
//         fetchSavingPlans();
//       }
//     } catch (error) {
//       console.error('Error adding saving plan:', error);
//     }
//   };

//   const fetchSavingPlans = async () => {
//     try {
//       const response = await axios.get(
//         'https://api.vlnidhi.com/api/admin/savings-account-schemes/get-all-saving-account-schemes',
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setSavingPlans(response.data);
//     } catch (error) {
//       console.error('Error fetching saving plans:', error);
//     }
//   };

//   useEffect(() => {
//     fetchSavingPlans();
//   }, []);

      
//   return (
   
//     <Container maxWidth="lg">
//       <Typography 
//         sx={{ 
//           marginTop: { xs: '5%', md: '2%' }, 
//           fontSize: { xs: '20px', md: '24px' }, 
//           fontWeight: 600, 
//           lineHeight: '36px', 
//           textAlign: 'start' 
//         }}
//       >
//         Saving Plan Master
//       </Typography>

//       <Paper sx={{ padding: '2%', marginTop: '2%' }}>
//         <Typography 
//           sx={{ 
//             fontSize: { xs: '18px', md: '20px' }, 
//             fontWeight: 500, 
//             marginBottom: '2%' 
//           }}
//         >
//           Saving Plan Master
//         </Typography>

//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Scheme Name*
//             </Typography>
//             <TextField
//               fullWidth
//               name="schemeName"
//               value={schemeData.schemeName}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Effective Date*
//             </Typography>
//             <TextField
//               fullWidth
//               type="date"
//               name="effectiveDate"
//               value={schemeData.effectiveDate}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Scheme Created By*
//             </Typography>
//             <TextField
//               fullWidth
//               name="schemeCreatedBy"
//               value={schemeData.schemeCreatedBy}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Annual R.O.I (%)*
//             </Typography>
//             <TextField
//               fullWidth
//               name="annualRoi"
//               value={schemeData.annualRoi}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Min. Opening Amount*
//             </Typography>
//             <TextField
//               fullWidth
//               name="minOpeningAmount"
//               value={schemeData.minOpeningAmount}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Monthly Avg. Bal.*
//             </Typography>
//             <TextField
//               fullWidth
//               name="monthlyAvgBalance"
//               value={schemeData.monthlyAvgBalance}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Lock In balance*
//             </Typography>
//             <TextField
//               fullWidth
//               name="lockInBalance"
//               value={schemeData.lockInBalance}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               SMS Charges *
//             </Typography>
//             <TextField
//               fullWidth
//               name="smsCharges"
//               value={schemeData.smsCharges}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>

//         <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                     }}
//                   >
//                   SMS Interval*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     name="smsInterval"  
//                     value={schemeData.smsInterval}
//                     onChange={handleInputChange}        
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="Quarterly">Quarterly</MenuItem>
//                     <MenuItem value="Half Yearly">Half Yearly</MenuItem>
//                   </TextField>
//          </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               SFree IFSC Collection(p.m)*
//             </Typography>
//             <TextField
//               fullWidth
//               name="freeIfscCollection"
//               value={schemeData.freeIfscCollection}
//               onChange={handleInputChange}        
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//             Free Transfers(p.m)*
//             </Typography>
//             <TextField
//               fullWidth
//               name='freeTransfers'
//               value={schemeData.freeTransfers}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Single Txn. Limit*
//             </Typography>
//             <TextField
//               fullWidth
//               name='singleTxnLimit'
//               value={schemeData.singleTxnLimit}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//              Daily MAX Limit*
//             </Typography>
//             <TextField
//               fullWidth
//               name='dailyMaxLimit'
//               value={schemeData.dailyMaxLimit}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//              Weekly MAX Limit*
//             </Typography>
//             <TextField
//               fullWidth
//               name='weeklyMaxLimit'
//               value={schemeData.weeklyMaxLimit}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Monthly MAX Limit*
//             </Typography>
//             <TextField
//               fullWidth
//               name='monthlyMaxLimit'
//               value={schemeData.monthlyMaxLimit}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//              Service Charges *
//             </Typography>
//             <TextField
//               fullWidth
//               name='serviceCharges'
//               value={schemeData.serviceCharges}
//               onChange={handleInputChange}
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>

//         <Typography
//                     sx={{
//                       width: "100%",
//                       fontSize: { xs: "16px", md: "18px" },
//                       marginBottom: "8px",
//                     }}
//                   >
//                  Service Interval*
//                   </Typography>
//                   <TextField
//                     fullWidth
//                     select
//                     name="serviceInterval"  
//                     value={schemeData.serviceInterval}
//                     onChange={handleInputChange}        
//                     InputProps={{
//                       style: {
//                         borderRadius: "10px",
//                       },
//                     }}
//                   >
//                     <MenuItem value="Quarterly">Quarterly</MenuItem>
//                     <MenuItem value="Half Yearly">Half Yearly</MenuItem>
//                   </TextField>
//          </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               SFree IFSC Collection(p.m)*
//             </Typography>
//             <TextField
//               fullWidth
//               name="freeIfscCollection"
//               value={schemeData.freeIfscCollection}
//               onChange={handleInputChange}        
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//              Card Limit Monthly*
//             </Typography>
//             <TextField
//               fullWidth
//               name="cardLimitMonthly"
//               value={schemeData.cardLimitMonthly}
//               onChange={handleInputChange} 
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <Typography 
//               sx={{ 
//                 width: '100%', 
//                 fontSize: { xs: '16px', md: '18px' }, 
//                 marginBottom: '8px' 
//               }}
//             >
//               Card Total Limit Yearly*
//             </Typography>
//             <TextField
//               fullWidth
//               name="cardTotalLimitYearly"
//               value={schemeData.cardTotalLimitYearly}
//               onChange={handleInputChange} 
//               sx={{
//                 backgroundColor: '#FFFFFF',
//                 borderRadius: '10px',
//                 '& .MuiInputBase-root': {
//                   height: { xs: '40px', md: '48px' },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//          {/* Save Button */}
//       <Grid container justifyContent="flex-end" sx={{ marginTop: '2%' }}>
//         <Button
//             variant="contained"
//             onClick={handleSubmit}
//             sx={{
//             width: { xs: '100%', md: '247px' },
//             height: '48px',
//             backgroundColor: '#1976d2',
//             borderRadius: '10px',
//             padding: '12px 103px',
//             fontSize: '16px',
//             fontWeight: 'bold',
//             textAlign: 'center',
//             marginTop: '2px',
//             }}
//         >
//             Save
//         </Button>
//         </Grid>
//       </Paper>

//        {/* Second Paper (Table Section) */}
//     <Paper sx={{ padding: '2%', marginTop: '4%', borderRadius: '10px 10px 0 0' }}>
//     <Typography 
//           sx={{ 
//             fontSize: { xs: '18px', md: '20px' }, 
//             fontWeight: 500, 
//             marginBottom: '2%' 
//           }}
//         >
//          Scheme List
//         </Typography>

//   <TableContainer  component={Paper} sx={{ borderRadius: '12px' }}>
//         <Table sx={{ minWidth: 650 }}>
//           <TableHead>
//             <TableRow sx={{ backgroundColor: '#413AF1' }}>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Scheme Code</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Scheme Name</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Int. Rate (%)</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Opening Amount</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Avg. Balance</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Lock In Balance</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>SMS Mode</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>SMS Charges</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Card Charges</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Card Limit Mly</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Card Limit Yly</TableCell>
//               <TableCell sx={{ color: 'white', fontSize: { xs: '12px', md: '14px' }, fontWeight: 'bold', textAlign: 'center' }}>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {savingPlans.map((plan, index) => (
//               <TableRow key={index}>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px',fontWeight:800 }, textAlign: 'center' }}>{plan.schemeCode}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.schemeName}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.annualRoi}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.minOpeningAmount}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.monthlyAvgBalance}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.lockInBalance}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.smsInterval}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.smsCharges}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.cardCharge}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.cardLimitMonthly}</TableCell>
//                 <TableCell sx={{ fontSize: { xs: '10px', md: '12px' }, textAlign: 'center' }}>{plan.cardTotalLimitYearly}</TableCell>
//                 <TableCell>
//                   <IconButton>
//                     <VisibilityIcon />
//                   </IconButton>
//                   <IconButton>
//                     <CloseIcon />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
// </Paper>
//  </Container>
//   );
// }

// export default SavingPlanMaster;
