import React from "react";

export const PageNotFound = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", color: "red" }}>
      <h1>
        Page Not Found
      </h1>
    </div>
  );
};
